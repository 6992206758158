import React from "react";
import {
  Button, Form, FormGroup, Input, Label, FormText, Col, Row
} from "reactstrap";
import InputGroup from "reactstrap/lib/InputGroup";
import { formatNumber } from '../../helpers/utils';

var DatePicker = require("reactstrap-date-picker");

class Product extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      option1: 0,
      option2: 0,
      option3: 0,
      option4: 0,
      option5: 0,
      price: this.props.price,
      value: new Date().toISOString(),
      formattedValue: '',
      totalPrice: this.props.price,
      slots: this.props.slots
    }
  }

  handleDateChange(value, formattedValue) {
    this.setState({
      value: value,
      formattedValue: formattedValue
    })
    if (formattedValue) {
      const fullDate = formattedValue.replaceAll('/', '-');
      const dd = fullDate.substring(8, 10);
      const yyyymm = fullDate.substring(0, 7);

      if (!!this.props.slots) {
        const slots = this.props.slots[yyyymm];
        if (slots) {
          const slotAvailable = slots[dd].numberOfAvailableSlots;
          this.setState({
            slots: slotAvailable
          })
          this.props.product['scheduledDate'] = fullDate;
        } else {
          alert('No slots for this month yet');
        }
      } else {
        alert('Not available.')
      }
    }
  }

  componentDidUpdate() {
    var hiddenInputElement = document.getElementById("example-datepicker");
  }

  handleSingSelectChange = (e) => {
    let price = 0;
    let optId = '';
    let selectedOption = {};
    let selectedVariation = {};

    const customOrder = e.target.attributes.selectedoption.value.split(',');
    selectedVariation = this.getVariation(customOrder[0]);
    selectedOption = this.getOption(selectedVariation.optionList, customOrder[1]);
    price = selectedOption.price;
    optId = e.target.attributes.name.value;

    this.setState({
      ["option" + optId]: price,
    })
    this.props.product['addOn' + optId] = { variation: selectedVariation, option: selectedOption };
  }

  handleMultipleSelectChange = (e) => {
    let price = 0;
    let optId = '';
    let selectedOption = {};
    let selectedVariation = {};

    const customOrder = e.target.attributes.selectedoption.value.split(',');
    selectedVariation = this.getVariation(customOrder[0]);
    selectedOption = this.getOption(selectedVariation.optionList, customOrder[1]);
    price = selectedOption.price;
    optId = e.target.attributes.name.value;

    // If checkbox type
    if (selectedVariation.type == 'Multiple') {
      if (e.target.checked) {
        price = this.state.["option" + (optId)] + price;
      } else {
        price = this.state.["option" + (optId)] - price;
      }
    }
    this.setState({
      ["option" + optId]: price,
    })
    this.props.product['addOn' + optId] = { variation: selectedVariation, option: selectedOption };
  
  }

  handSpecialInstructionChange = (e) => {
    this.props.product['specialRequest'] = e.target.value;
  }

  totalPrice() {
    let total = this.state.price;
    for (let i = 1; i <= 5; i++) {
      total = total + Number(this.state.["option" + i]);
    }
    return total
  }

  getVariation(variationId) {
    let getvariation;
    this.props.variationList.map((variation, i) => {
      if (variation.variationId == variationId) {
        getvariation = variation;
      }
    })
    return getvariation;
  }

  getOption(optionList, optionId) {
    let getoption;
    optionList.map((option, i) => {
      if (option.optionId == optionId) {
        getoption = option;
      }
    })
    return getoption;
  }

  render() {
    const currentDate = new Date().toISOString();
    return (
      <>
        <Form>
          <Label className="right label label-price" onChange={() => { this.setState({ totalPrice: this.totalPrice() }) }}>Price:  <span> {formatNumber(this.totalPrice())}</span></Label>
          {this.props.variationList && this.props.variationList.map((variation, i) => {
            const optionType = variation.type;
            const optionList = variation.optionList;
            const variationName = variation.variationName;
            i = i + 1;
            if (optionType === 'Single') {
              return (
                <FormGroup key={variation.variationId} tag="fieldset" >
                  <Label for="exampleSelect">Choose {variationName} <span style={{ color: '#F69320' }}>+ (P {this.state.["option" + (i)]} )</span></Label>
                  {optionList && optionList.map((option, index) => {
                    return (
                      <FormGroup key={option.optionId} check>
                        <Label check >
                          <Input price={option.price} onClick={this.handleSingSelectChange} selectedoption={variation.variationId + ',' + option.optionId} type="radio" id="radio" name={i} />{' '}
                          {option.optionName}
                        </Label>
                      </FormGroup>
                    )
                  })}
                </FormGroup>
              )
            } else if (optionType === 'Multiple') {
              return (
                <FormGroup key={variation.variationId} check>
                  Choose {variationName} <span style={{ color: '#F69320' }}>+ (P {this.state.["option" + (i)]} )</span><br />
                  <Row>
                    {optionList && optionList.map((option, index) => {
                      return (
                        <Label className="right" check>
                          <Input className="form-check-input" price={option.price} onClick={this.handleMultipleSelectChange} selectedoption={variation.variationId + ',' + option.optionId} name={i} type="checkbox" />{' '}
                          {option.optionName}
                        </Label>
                      )
                    })}
                  </Row>
                </FormGroup>
              )
            } 
          })}
          <FormGroup>
            <Label for="specialInstructions">Special Instructions</Label>
            <Input type="textarea" onChange={this.handSpecialInstructionChange} name="specialInstructions" rows="3" id="specialInstructions" />
          </FormGroup>
        </Form>
      </>
    );
  }
}
export default Product;