import firebase from 'firebase'
import { updateNotifToken } from 'helpers/API';

export const initializeFirebase = () => {
  var firebaseConfig = {
    apiKey: "AIzaSyBNU6D4JveQMeBLErASbBVXbda0m94DV5E",
    authDomain: "timmyexpress-34db5.firebaseapp.com",
    projectId: "timmyexpress-34db5",
    storageBucket: "timmyexpress-34db5.appspot.com",
    messagingSenderId: "328582281820",
    appId: "1:328582281820:web:2c4ff63d7c6518d5160499",
    measurementId: "G-QGNLTVV0QY"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
}
export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log('user token:', token);


    updateNotifToken(token).then(res => {
      if (res) {
        console.log('updatedToken', token);
      }
    });

    return token;
  } catch (error) {
    console.error(error);
  }
}