import { isEqualVariant } from '../helpers/utils';

const Storage = (cartItems) => {
    localStorage.setItem('cart', JSON.stringify(cartItems.length > 0 ? cartItems : []));
}

const init = (cartItems) => {
    cartItems.forEach(product =>
        product['addOnsPrice'] = computeTotalAddons(product)
    )
}

export const sumItems = cartItems => {
    Storage(cartItems);
    init(cartItems);
    let itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
    let scheduledDate = cartItems.length > 0 ? cartItems[cartItems.length - 1].scheduledDate : 'YYYY/MM/DD';
    let shopId = cartItems.length > 0 ? cartItems[cartItems.length - 1].shopId : 0;
    let shopName = cartItems.length > 0 ? cartItems[cartItems.length - 1].shopName : 0;
    let shopImage = cartItems.length > 0 ? cartItems[cartItems.length - 1].image1 : 0;
    let hasDeliveryService = cartItems.length > 0 ? cartItems[cartItems.length - 1].hasDeliveryService : false;
    let preparationTime = cartItems.length > 0 ? cartItems[cartItems.length - 1].preparationTime : 45;
    let availableForPickup = cartItems.length > 0 ? cartItems[cartItems.length - 1].availableForPickup : false;

    let total = cartItems.reduce((total, product) => total + (product.price + product.addOnsPrice) * product.quantity, 0).toFixed(2);
    let deliveryFee = cartItems.length > 0 ? cartItems[cartItems.length - 1].deliveryFee : '';
    let minimumOrderAmt = cartItems.length > 0 ? cartItems[cartItems.length - 1].minimumOrderAmt : 0;

    return { itemCount, total, scheduledDate, shopId, shopName, shopImage, deliveryFee, hasDeliveryService, preparationTime, availableForPickup, minimumOrderAmt }
}


export const computeTotalAddons = product => {
    let total = 0;
    for (let i = 1; i <= 5; i++) {
        if (!!product && !!product["addOn" + i]) {
            let selectedOption = product["addOn" + i].option;
            if (!!selectedOption) {
                total = total + selectedOption.price;
            }
        }
    }
    return total;
}
export const CartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            state.cartItems.push({
                ...action.payload,
                quantity: 1
            })
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "ADD_VOUCHER":
            if (state.cartItems.length > 0) {
                state.cartItems[0].voucher = action.payload
            }
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "ADD_DISCOUNT":
            if (state.cartItems.length > 0) {
                state.cartItems[0].discount = action.payload
            }
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "SET_DELIVERY_OPTION":
            if (state.cartItems.length > 0) {
                state.cartItems[0].deliveryOption = action.payload
            }
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "SET_DELIVERY_FEE":
            if (state.cartItems.length > 0) {
                state.cartItems[0].deliveryFee = action.payload
            }
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "REMOVE_ITEM":
            const filtered = state.cartItems.filter(item => (
                (item.productId == action.payload.productId && !isEqualVariant(item, action.payload))
                || (item.productId !== action.payload.productId)
            ));
            return {
                ...state,
                ...sumItems(filtered),
                cartItems: [...filtered]
            }
        case "INCREASE":
            state.cartItems[state.cartItems.findIndex(item => item.productId === action.payload.productId &&
                isEqualVariant(item, action.payload))].quantity++
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "DECREASE":
            state.cartItems[state.cartItems.findIndex(item => item.productId === action.payload.productId &&
                isEqualVariant(item, action.payload))].quantity--
            return {
                ...state,
                ...sumItems(state.cartItems),
                cartItems: [...state.cartItems]
            }
        case "CHECKOUT":
            return {
                cartItems: [],
                checkout: true,
                ...sumItems([]),
            }
        case "CLEAR":
            return {
                cartItems: [],
                ...sumItems([]),
            }
        default:
            return state

    }
}