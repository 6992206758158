import React from "react";
import { Button, CardBody, Container, InputGroup, InputGroupAddon, Input, Col,Row, Modal, ModalBody, FormText} from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Calendar from "./Calendar";
import { FACEBOOK_PAGE } from "constants/index";
import { Link } from "react-router-dom";
import SectionCarousel from "./SectionCarousel";
var DatePicker = require("reactstrap-date-picker");

const LandingPageHeader = () => {
  let pageHeader = React.createRef();
  let currentDate = new Date().toISOString();

  const [modal, setModal] = React.useState(false);
  const [value, setValue] = React.useState(currentDate);

  const toggleModal = (product) => {
    setModal(!modal);
  };
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const handleDateChange = (value, formattedValue) => {
    setValue(value);
  }

  return (

    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/landing/food2.jpg") + ")",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <div className="motto">
            {/* <Col className="m-auto" sm="12" lg="5" md="8">
              <h2 className="font-weight-bold">Food prepared just for you</h2>
              <br />
              <Input placeholder="Cake, Lechon Belly.." />
              <InputGroup>
                <Calendar />
                <InputGroupAddon addonType="append">
                  <Button href="/list" color="success">Find Nearby</Button>
                </InputGroupAddon>
              </InputGroup>
            </Col> */}
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader></ModalHeader>
              <ModalBody>
                <br />

                <img alt="..." className="mr-auto" width="50%" src="assets/img/mycook-2.png" />
                <b>Hi, I'm Timmy.</b>
                <br />
                <small>
                We are not fully operational yet. Fret not! We're currently establishing partnerships with the best restaurants in town to offer you more selections to choose from.
                  Follow us on <a href="https://www.facebook.com/im.timmy.ph">Facebook</a> for an exciting announcement in the coming days. For questions, drop us an email on contact@im-timmy.com.
                 </small>
                <br /><br /><br />

              </ModalBody>
            </Modal>
            <Row>
            <Col className="m-auto" xs="6" sm="6" lg="5" md="8">
              <h2 className="font-weight-bold">Launching soon!</h2>
                  <Button href="/restaurants" className="btn-round btn-timmy">Discover</Button>
               
            </Col>
            <Col className="m-auto" xs="6" sm="6" lg="4" md="4">
            <SectionCarousel/>

               
            </Col>
            </Row>
            
          </div>
        </Container>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")",
          }}
        />
      </div>
    </>
  );
}

export default LandingPageHeader;
