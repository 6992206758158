import React from "react";

import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import LandingPage from "views/LandingPage.js";
import PaymentsPage from "views/PaymentsPage.js";
import CartPage from "views/CartPage.js"
import CartContextProvider from "contexts/CartContext";
import MyOrdersPage from "views/MyOrdersPage";
import UserProfilePage from "views/UserProfilePage";
import LoginPage from "views/LoginPage";
import AuthRoute from "./AuthRoute";
import AuthContextProvider from "contexts/AuthContext";
import RegisterPage from "views/RegisterPage";
import OAuth2RedirectHandler from "components/user/OAuth2RedirectHandler";
import MerchantPage from "views/MerchantPage";
import RiderPage from "views/RiderPage";
import ShopPage from "views/ShopPage";
import ForgotPassword from "components/user/ForgotPassword";
import ResetPassword from "components/user/ResetPassword";
import AddressPage from "views/AddressPage";
import Waiting from "components/cart/Waiting";
import RestaurantResultPage from "components/restaurant/RestaurantResultPage";
import RestaurantPage from "components/restaurant/RestaurantPage.js";
import ShopResultsPage from "components/shop/ShopsResultPage";
import AboutPage from "views/AboutPage";
import InstallPWA from "pwa/InstallPWA";

import { Button, Row, Col } from "reactstrap";
import Notification from "components/common/Notification";

function App() {

    return (
        <CartContextProvider>          
            <AuthContextProvider>
                <Notification/>
                <BrowserRouter>
                    <Switch>
                        <Route
                            path="/about"
                            render={(props) => <LandingPage {...props} />}
                        />
                        <Route
                            path="/policy"
                            render={(props) => <AboutPage {...props} />}
                        />
                        <Route
                            path="/eats/:shopId"
                            render={(props) => <RestaurantPage {...props} />}
                        />
                        <Route
                            path="/restaurants"
                            render={(props) => <RestaurantResultPage {...props} />}
                        />
                        <Route
                            path="/shops"
                            render={(props) => <ShopResultsPage {...props} />}
                        />
                        <Route
                            path="/shop/:shopId"
                            render={(props) => <ShopPage {...props} />}
                        />
                        <Route
                            path="/cart"
                            render={(props) => <CartPage {...props} />}
                        />
                        <Route
                            path="/login"
                            render={(props) => <LoginPage {...props} />}
                        />
                        <Route
                            path="/register"
                            render={(props) => <RegisterPage {...props} />}
                        />
                        <Route
                            path="/merchant"
                            render={(props) => <MerchantPage {...props} />}
                        />
                        <Route
                            path="/rider"
                            render={(props) => <RiderPage {...props} />}
                        />

                        <Route
                            path="/oauth2/redirect"
                            render={(props) => <OAuth2RedirectHandler {...props} />}
                        />
                        <Route path="/recoverpassword" render={(props) => <ForgotPassword {...props} />} />
                        <Route path="/reset/:token" render={(props) => <ResetPassword {...props} />} />

                        {/* Private Routes */}
                        {/* <AuthRoute path="/restaurants" render={(props) => <RestaurantResultPage {...props} />}  type="private" /> */}
                        <AuthRoute path="/pay" render={PaymentsPage} type="private" />
                        <AuthRoute path="/myorders" render={MyOrdersPage} type="private" />
                        <AuthRoute path="/myaccount" render={UserProfilePage} type="private" />
                        <AuthRoute path="/updateAddress" render={AddressPage} type="private" />
                        <AuthRoute path="/waiting" render={(props) => <Waiting {...props} />} type="private" />
                        <Redirect to="/restaurants" />
                    </Switch>
                </BrowserRouter>
            </AuthContextProvider>
        </CartContextProvider>

    )
}


export default App;
