import React from "react";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Card
} from "reactstrap";
import Calendar from "components/common/Calendar";
import CardBody from "reactstrap/lib/CardBody";

function SearchBar() {

  return (
    // <Col className="ml-auto mr-auto">
    //   <Card className="card-raised card-form-horizontal no-transition card">
    //     <CardBody>
    //       <Input placeholder="Cake, Lechon Belly.." />
    //       <InputGroup>
    //         <Calendar />
    //         <InputGroupAddon addonType="append">
    //           <Button href="/list" color="success">Find Nearby</Button>
    //         </InputGroupAddon>
    //       </InputGroup>
    //     </CardBody>
        
    //   </Card>

    // </Col>
    <></>
             
     
   
  );
}

export default SearchBar;
