import React, { Component } from 'react';
import { ACCESS_TOKEN } from '../../constants';
import { Redirect } from 'react-router-dom'
import { getCurrentUser } from 'helpers/API';
import { AuthContext } from 'contexts/AuthContext';

class OAuth2RedirectHandler extends Component {
    static contextType = AuthContext;

    getUrlParameter(name) {
        console.log('test: ' + name)
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    render() {
        const auth = this.context;

        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');
        const path = this.getUrlParameter('path');

        if (token) {
            localStorage.setItem(ACCESS_TOKEN, token);
            getCurrentUser().then(response => {
                auth.success(response);
            })
            return <Redirect to={{
                pathname: !!path? path: '/',
            }} />;
        } else {
            return <Redirect to={{
                pathname: "/login",
                state: {
                    from: this.props.location,
                    error: error
                }
            }} />;
        }
    }
}

export default OAuth2RedirectHandler;