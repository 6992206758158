import React from "react";
import { useHistory } from "react-router-dom";
import { forgotPassword, validateResetToken, resetPassword } from 'helpers/API';

import { Button, Card, Form, Input, InputGroup, Container, Row, Col, Modal, ModalBody, FormGroup } from "reactstrap";
import logo from "assets/img/mycook-3.png";
import FormText from "reactstrap/lib/FormText";
import loadingGif from "assets/img/loading2.gif";

const ForgotPassword = (props) => {
    const [emailAddress, setEmailAddress] = React.useState("");
    const [emailAddressErr, setEmailAddressErr] = React.useState("");
    const [modal, setModal] = React.useState(false);
    const [modalMsg, setModalMsg] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    const history = useHistory();

    const toggleModal = (msg) => {
        setModal(!modal);
        setModalMsg(msg);
    };

    const handleEmailChange = event => {
        setEmailAddress(event.target.value);
    }

    const handleFormSubmit = () => {

        toggleModal(<>Sending reset link to your email <img width="10%" src={loadingGif} alt="Card image cap" /></>);
        forgotPassword(emailAddress)
            .then(response => {
                if(response){
                    setModal(false);
                    setEmailAddressErr('Password reset link is sent to your email');
                }
            }).catch(error => {
                toggleModal(error.message);
                setEmailAddressErr(error.message);
            });
    }

    return (
        <>
            <div className="main ps">
                <br /><br /><br /><br />
                <div className="section">
                    <Modal className="modal-dialog-centered" isOpen={modal} toggle={toggleModal}>
                        <ModalBody className="text-center">
                             {modalMsg}

                        </ModalBody>
                    </Modal>
                    <Col className="m-auto" sm="12" lg="8" md="8">
                        {/* {loading ? <><img width="10%" src={loadingGif} alt="Card image cap" /> Uploading item. Please wait</> : ''} */}
                        <Col className="ml-auto mr-auto" lg="4">
                            <Card className="card card-body mt-3 ml-auto mr-auto bg-light text-dark">
                                <img alt="..." className="mx-auto" width="50%" src={logo} />
                                <h6 className="mx-auto text-muted">Recover your password</h6>
                                {emailAddressErr ? <FormText color="danger" className="mx-auto">{emailAddressErr}</FormText> : ''}
                                <br />
                                <Form >
                                    <FormGroup>
                                        <label>Email Address</label>
                                        <Input placeholder="Email Address" name="username" type="text" value={emailAddress} onChange={handleEmailChange} />
                                        <Button onClick={handleFormSubmit} block className="btn-round mt-2 btn-timmy">
                                            Reset Password </Button>
                                    </FormGroup>

                                </Form>
                                <Button className="btn-link text-muted"  href="/login" >  Go back to login </Button>

                            </Card>
                        </Col>
                    </Col>
                </div>

            </div>
        </>
    );
}

export default ForgotPassword;
