import React from "react";

import {
    Container, Col, Row
} from "reactstrap";

import SearchNavBar from "components/search/SearchNavBar.js";
import SearchBar from "components/search/SearchBar";
import Shops from "./Shops.js"
import { getShopsByServiceType } from 'helpers/API';
import Footer from "components/common/Footer";
import { useHistory } from "react-router-dom";
import InstallPWA from "pwa/InstallPWA.js";
import { AuthContext } from "contexts/AuthContext.js";
import { getMerchants } from "helpers/API.js";
import LocationNavBar from "components/search/LocationNavBar.js";


const ShopResultsPage = (props) => {
    const [shops, setShops] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const [pageLoad, setPageLoad] = React.useState(false);

    const { isAuthUser, user, logout } = React.useContext(AuthContext);

    React.useEffect(() => {
        if (user.address && user.address.locationAddress && user.address.latitude && user.address.longitude) {
            getMerchants('Shop',user.address.city).then((data) => {
                setShops(data);
                setLoading(false);
                setPageLoad(true);

                
            })
                .then(() => {
                    setLoading(false);
                }).catch(err => {
                    console.log(err.log);
                }).catch(err => {
                    console.log(err.log);
                });

        } else {
            history.push("/updateAddress")
        }

        
    }, []);

    const handleSelectedChange = (type) => {
        history.push("/restaurants")
    }

    return (
        <>
            {loading ?
                <div className="section section-white text-center">
                    <Container>
                        <div className="row mt-5">
                            <Col className="ml-auto mr-auto mt-5" sm="12">
                                <br />
                                <img alt="Loading" className="img-responsive timmy-centered" src="assets\img\loading.gif"/>
                            </Col>
                        </div>
                    </Container>
                </div>
                :
                <>
                    <LocationNavBar />
                    <div className="main">
                        <br />
                        <div className="section section-dark text-center">
                        <InstallPWA/>

                            <Container>
                                <Row>
                                    <Col md="3" lg="3" xs="6" onClick={() => handleSelectedChange("Restaurant")}>
                                        <img className="mt-0 img-responsive closedshop right" top="true" width="100%"  src="assets/img/eats.png" />
                                    </Col>
                                    <Col md="3" lg="3" xs="6" onClick={() => handleSelectedChange("Shop")}>
                                        <img className="mt-0 img-responsive left" top="true" width="100%"  src="assets/img/shops.png" />
                                    </Col>
                                </Row>
                                <SearchBar />
                                <Shops list={shops} />
                            </Container>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}
export default ShopResultsPage;

