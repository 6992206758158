import React from "react";

import Orders from "components/user/Orders.js";

import { Container, Row } from "reactstrap";

function MyOrdersPage() {
    document.documentElement.classList.remove("nav-open");
    return (
        <>
            <Orders />

        </>
    );
}

export default MyOrdersPage;
