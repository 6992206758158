import React from "react";

import UpdateAddress from "./UpdateAddress"

function AddressPage() {

    return (
        <>
            <UpdateAddress />

        </>
    );
}

export default AddressPage;
