import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import LandingNavBar from "components/common/LandingNavBar.js";
import LandingPageHeader from "components/common/LandingPageHeader.js";
import Footer from "components/common/Footer.js";
import FormText from "reactstrap/lib/FormText";
import CardHeader from "reactstrap/lib/CardHeader";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import { ERROR_REQUIRED } from "constants/index"
import { sendMerchantApplication } from "helpers/API"

function MerchantPage() {

  let pageHeader = React.createRef();

  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');

  const [businessName, setBusinessName] = React.useState('');
  const [businessNameError, setBusinessNameError] = React.useState('');

  const [contactNumber, setContactNumber] = React.useState('');
  const [contactNumberError, setContactNumberError] = React.useState('');

  const [street, setStreet] = React.useState('');
  const [streetError, setStreetError] = React.useState('');

  const [brgy, setBrgy] = React.useState('');
  const [brgyError, setBrgyError] = React.useState('');

  const [city, setCity] = React.useState('');
  const [cityError, setCityError] = React.useState('');

  const [images, setImages] = React.useState('');
  const [imagesError, setImagesError] = React.useState([]);

  const [modal, setModal] = React.useState(false);

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handleBusinessNameChange = (event) => {
    setBusinessName(event.target.value);
    setBusinessNameError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handleContactNumberChange = (event) => {
    setContactNumber(event.target.value.replace(/[^\d+\.?\d*]/, ''));
    setContactNumberError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }
  const handleStreetChange = event => {
    setStreet(event.target.value);
    setStreetError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }
  const handleBrgyChange = event => {
    setBrgy(event.target.value);
    setBrgyError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }
  const handleCityChange = event => {
    setCity(event.target.value);
    setCityError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handleFilesChange = event => {
    setImages(event.target.files[0]);
    // setImageName(event.target.value);
    // setImageError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const validateInputs = () => {
    let hasErrors = false;
    if (!name) {
      setNameError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!businessName) {
      setBusinessNameError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!contactNumber) {
      setContactNumberError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!email) {
      setEmailError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!street) {
      setStreetError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!brgy) {
      setBrgyError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!city) {
      setCityError(ERROR_REQUIRED);
      hasErrors = true;
    }
    return hasErrors;
  }

  const submit = () => {

    if (!validateInputs()) {
      const address = {
        street: street,
        brgy: brgy,
        city: city
      }

      const body = {
        shopName: businessName,
        contactNumber: contactNumber,
        ownerName: name,
        emailAddress: email,
        address: address
      }

      sendMerchantApplication(JSON.stringify(body)).then(response => {

        console.log(response);
        toggleModal();
        reset();
      })
    }

  }

  const reset = () => {
    setName('');
    setContactNumber('');
    setBusinessName('')
    setEmail('')
    setStreet('')
    setBrgy('')
    setCity('')
  }


  return (
    <>
      <LandingNavBar />
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/landing/food2.jpg") + ")",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="mr-auto ml-auto mt-5" md="9" xs="12">
              <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader>

                </ModalHeader>
                <ModalBody>
                  <h4 className="mt-0">Thank you!</h4> <br/>
                
                  <small>
                    Your application has been submitted. Please expect a call from us within 3-5 business days. If you have questions, drop us an email on contact@im-timmy.com. Have a good day!
                    </small>
                    {/* <Button color="success">Close</Button> */}
                </ModalBody>
              </Modal>
              <Card>
                <CardHeader>
                  <h3 className="text-muted text-center">Merchant Quick Info</h3>
                </CardHeader>
                <CardBody>
                  <li>Any kind of establishment within Bayugan City can apply as Merchant.</li> <br />
                  <li>Sign Up is 100% FREE </li><br />
                  <li>Merchants without delivery service will be handled by Timmy </li>       <br />
                  <li>Business hours is up to the Merchant </li>         <br />
                  <li>Merchant can update menu status anytime</li>  <br />
                  <li>Mode of Payment is Cash-on Delivery. Online payments will be available soon.</li>                                      <br />
                  {/* <li>Boost credibility through user ratings and reviews from verified customers </li>                                       <br /> */}
                  <li>A web fee of 10% will be credited to Timmy for every successful transaction</li> <br />
                  <li>Once approved, Timmy operates its web support system 24/7</li> <br />

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")",
          }}
        />
      </div>
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                {/* <p>To all the foodies in Bayugan City, no need to go out to fill something in your tummies. Just this few taps: </p> */}
                <img alt="..." className="img-fluid" src={require("assets/img/use-case-3.PNG")} />
                <h2 className="title">Let's go digital!</h2>
                <h5>
                  Increase your sales, cut down on wait times and eliminate customer misunderstanding. The menu, variation, checkout information and confirmation will now be done in just a few taps!
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark landing-section text-white ">
          <Container>
            <Row>
              <Col className="mr-auto ml-auto" md="10">
                <h3 className="mb-3 mt-0">Apply as a Merchant</h3>
                <FormGroup>
                  <Row>
                    <Col md="6">
                      <Label>Name</Label>
                      <Input type="text" placeholder="Owner / Manager name" value={name} onChange={handleNameChange}></Input>
                      <FormText className="text-danger">{nameError ? nameError : ''}</FormText>
                    </Col>
                    <Col md="6">
                      <Label>Email</Label>
                      <Input type="text" placeholder="Email Address" value={email} onChange={handleEmailChange}></Input>
                      <FormText className="text-danger">{emailError ? emailError : ''}</FormText>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Label>Business Name</Label>
                      <Input type="text" placeholder="Business / Restaurant Name" value={businessName} onChange={handleBusinessNameChange}></Input>
                      <FormText className="text-danger">{businessNameError ? businessNameError : ''}</FormText>
                    </Col>
                    <Col md="6">
                      <Label>Contact Number (11 digits)</Label>
                      <Input type="text" maxLength="11" placeholder="Business Contact Number" value={contactNumber} onChange={handleContactNumberChange}></Input>
                      <FormText className="text-danger">{contactNumberError ? contactNumberError : ''}</FormText>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <Label>Bldg, Street</Label>
                      <Input type="text" placeholder="Landmark, Bldg, St" value={street} onChange={handleStreetChange}></Input>
                      <FormText className="text-danger">{streetError ? streetError : ''}</FormText>
                    </Col>
                    <Col md="4">
                      <Label>Brgy</Label>
                      <Input type="text" placeholder="Brgy" value={brgy} onChange={handleBrgyChange}></Input>
                      <FormText className="text-danger">{brgyError ? brgyError : ''}</FormText>

                    </Col>
                    <Col md="4">
                      <Label>City</Label>
                      <Input type="text" placeholder="City" value={city} onChange={handleCityChange}></Input>
                      <FormText className="text-danger">{cityError ? cityError : ''}</FormText>

                    </Col>
                    {/* <Col md="4">
                      <Label>Upload Sample Menu Images</Label>
                      <Input className="p-1" type="file" name="file" id="exampleFile" value={images} onChange={handleFilesChange} />
                      <FormText className="p-1" color="muted">Please upload your item image in landscape orientation. Otherwise, it will be cropped into 4:3 aspect ratio</FormText>
                    </Col> */}
                  </Row>
                  <Button onClick={() => submit()} className="float-right mt-1" color="success"> Apply </Button>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </div>

      </div>

      <Footer />
    </>
  );
}

export default MerchantPage;
