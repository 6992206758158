import moment from 'moment';
import ReactDatetime from "react-datetime";

var _ = require('lodash');

export const formatNumber = number => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(number);
}

export const isObjectEqual = (a, b) => {
    return _.isEqual(a, b);
}

export const isObjectEmpty = (a) => {
    return _.isEmpty(a);
}

export const isEqualVariant = (product1, product2) => {
    let hasEqual = true;
    for (let i = 1; i <= 5; i++) {
        let product1Notes = product1['specialRequest'];
        let product2Notes = product2['specialRequest'];
        if(product1Notes != product2Notes){
            hasEqual = false;
        }
        let prod1addOn = product1["addOn" + i];
        let prod2addOn = product2["addOn" + i];
        if (!isObjectEqual(prod1addOn, prod2addOn)) {
            hasEqual = false;
        }
    }
    return hasEqual;
}

export const isInCart = (cart, product) => {
    return !!cart.find(item => isObjectEqual(item.productId, product.productId) && isEqualVariant(item, product))
}

export const convertToDate = (timestamp) => {
    const dateFormat = 'YYYY/MM/DD';
    return moment(timestamp).format(dateFormat);
}

export const displayDateAndTime = (date) => {
    const dateFormat = 'YYYY/MM/DD HH:mm:ss';
    return moment(date).format(dateFormat);
}

export const displayTime = (date) => {
    
    const dateFormat = 'hh:mm a';
    return date ? moment(date).format(dateFormat) : '';
}