import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { forgotPassword, validateResetToken, resetPassword } from 'helpers/API';

import { Button, Card, Form, Input, InputGroup, Container, Row, Col, Modal, ModalBody, FormGroup } from "reactstrap";
import { AuthContext } from "contexts/AuthContext";
import logo from "assets/img/mycook-3.png";
import FormText from "reactstrap/lib/FormText";
import { IMAGE_URL, ERROR_REQUIRED } from "constants/index"

const ResetPassword = (props) => {
    const [password, setPassword] = React.useState("");
    const [passwordErr, setPasswordErr] = React.useState("");

    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordErr, setconfirmPasswordErr] = React.useState("");

    const [modal, setModal] = React.useState(false);
    const [modalMsg, setModalMsg] = React.useState('');

    const [invalidToken, setInvalidToken] = React.useState(true);
    const [successReset, setSuccessReset] = React.useState(false);

    const history = useHistory();
    let { token } = useParams();

    const handlePasswordChange = event => {
        setPassword(event.target.value);
        setPasswordErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
        setPasswordErr(event.target.value.length > 0 && event.target.value.length < 8 ? 'Must be atleast 8 characters' : '')
    }

    const handleConfirmPasswordChange = event => {
        setConfirmPassword(event.target.value);
        setconfirmPasswordErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
        // setconfirmPasswordErr(event.target.value != password ? 'Password does not match' : '')
    }

    React.useEffect(() => {
        validateResetToken(token)
            .then(response => {
                setInvalidToken(false)
            }).catch(error => {
                setInvalidToken(true)
            });
    }, []);

    const toggleModal = (msg) => {
        setModal(!modal);
        setModalMsg(msg);
    };

    const handleFormSubmit = event => {

        if (validatePassword()) {
            return;
        }
        const body = {
            resetToken: token,
            newPassword: password
        }

        resetPassword(JSON.stringify(body))
            .then(response => {
                if (response) {
                    alert('Successfully reset');
                    history.push('/login');
                }
            }).catch(error => {
                toggleModal(error.message);
                setSuccessReset(false)

            });
    }

    const validatePassword = event => {
        let hasErrors = false;

        if (!password) {
            setPasswordErr(ERROR_REQUIRED)
            hasErrors = true;
        }

        if (password && password.length < 8) {
            setPasswordErr('Must be atleast 8 characters');
            hasErrors = true;
        }

        if (password != confirmPassword) {
            setconfirmPasswordErr('Password does not match');
            hasErrors = true;
        }
        return hasErrors;
    }

    return (
        <>
            <div className="main ps">
                <br /><br /><br /><br />
                <div className="section">
                    <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggleModal('')}>
                        <ModalBody className="text-center">
                            {modalMsg}
                        </ModalBody>
                    </Modal>
                    <Col className="m-auto" sm="12" lg="8" md="8">

                        <Col className="ml-auto mr-auto" lg="4">
                            <Card className="card card-body mt-3 ml-auto mr-auto bg-light text-dark">

                                {!invalidToken && !successReset ? <>
                                    <img alt="..." className="mx-auto" width="50%" src={logo} />
                                    <h6 className="mx-auto text-muted">Reset password</h6>
                                    <small className="mx-auto"> </small>

                                    <br />
                                    <Form >
                                        <FormGroup>
                                            <label>New Password</label>
                                            <Input placeholder="New Password" name="username" type="password" value={password} onChange={handlePasswordChange} />
                                            <FormText className="text-danger">  {passwordErr ? passwordErr : ''}</FormText>

                                            <label>Confirm Password</label>
                                            <Input placeholder="Confirm Password" name="username" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                            <FormText className="text-danger">  {confirmPasswordErr ? confirmPasswordErr : ''}</FormText>

                                            <Button onClick={handleFormSubmit} block className="btn-round btn-timmy">
                                                Reset </Button>
                                        </FormGroup>

                                    </Form>
                                    <Button
                                        className="btn-link text-muted"
                                        href="/login"
                                    >
                                        Go back to login
                                    </Button>
                                </>
                                    :
                                    <>
                                        <img alt="..." className="mx-auto" width="50%" src={logo} />
                                        <h6 className="mx-auto text-muted">Invalid Reset Password Link</h6>
                                        <br/>
                                        <Button href="/recoverpassword" block className="btn-round btn-timmy">
                                            Go back </Button>
                                    </>
                                }


                            </Card>
                        </Col>
                    </Col>
                </div>

            </div>
        </>
    );
}

export default ResetPassword;
