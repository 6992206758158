import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "reactstrap";


const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <>
      <Row align="middle" justify="center">
        <Col span={6}>
          <Button className="btn-round" color="warning" type="dashed" onClick={onClick}>
            Open via Timmy App &nbsp; <i className="nc-icon nc-mobile" /></Button>
        </Col>
      </Row>
    </>
  );
};

export default InstallPWA;