import React from "react";

import {
  Container, Col, Row, Button
} from "reactstrap";

import SearchNavBar from "components/search/SearchNavBar.js";
import SearchBar from "components/search/SearchBar";
import Restaurants from "./Restaurants.js"

import { getShopsByServiceType } from 'helpers/API';
import Footer from "components/common/Footer";
import { Link, useHistory } from "react-router-dom";
import InstallPWA from "pwa/InstallPWA.js";
import FooterCart from "components/common/FooterCart.js";
import FooterArrow from "components/common/FooterArrow.js";
import { AuthContext } from "contexts/AuthContext";
import { getMerchants } from "helpers/API.js";
import LocationNavBar from "components/search/LocationNavBar.js";

const RestaurantResultPage = (props) => {
  const [shops, setShops] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageLoad, setPageLoad] = React.useState(false);
  const history = useHistory();
  const { isAuthUser, user, logout } = React.useContext(AuthContext);

  const [change,hasChange] = React.useState(false)
  React.useEffect(() => {
    if (user.address && user.address.locationAddress && user.address.latitude && user.address.longitude) {

      getMerchants('Restaurant', user.address.city).then(data => {
        setShops(data);
        setLoading(false);
        setPageLoad(true);
      }).catch(err => {
        console.log(err)

        if (err == 'TypeError: Failed to fetch') {
          setLoading(false);
          setPageLoad(false)
        } else {
          setLoading(false);
          setPageLoad(true);

        }

      }).catch(err => {
        setLoading(false);
        setPageLoad(false)
      });


    } else {
      history.push("/updateAddress")
    }
  }, []);

  const handleSelectedChange = () => {
    history.push("/shops")
  }

  const refresh = () => {
    // history.push("/restaurants")
    window.location.href="https://timmyexpress.com/restaurants"
  }

  return (
    <>
      {loading ?
        <div className="section section-white text-center">
          <img alt="Loading" className="img-responsive timmy-centered" src="assets\img\loading.gif" />
        </div>
        : ''
      }
      {pageLoad ?
        <>
          <LocationNavBar />
          <div className="main">
            <br />
            <div className="section section-dark text-center">
              <InstallPWA />
              <Container>
              
                <Row>
                  <Col md="3" lg="3" xs="6" >
                    <img className="mt-0 img-responsive " onClick={() => refresh()} top="true" width="100%" src="assets/img/eats.png" />
                  </Col>
                  <Col md="3" lg="3" xs="6" onClick={() => handleSelectedChange("Shops")}>
                    <img className="mt-0 img-responsive closedshop" top="true" width="100%" src="assets/img/shops.png" />
                  </Col>
                </Row>
                <SearchBar />
                <Restaurants list={shops} />
              </Container>
            </div>
          </div>
          <Footer />
          {/* <FooterCart></FooterCart> */}
        </>
        : ''
      }

      {!loading && !pageLoad ?
        <div className="section section-white text-center">
          <Container>
            <div className="row mt-5">
              <Col className="ml-auto mr-auto mt-5" sm="12">
                <img alt="..." className="mx-auto" width="50%" src="assets/img/timmy3.png" />
                <br />
                <p className="text-default">You are offline </p>
              </Col>
            </div>
          </Container>
        </div>
        : ''}

    </>
  );
}
export default RestaurantResultPage;

