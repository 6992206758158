import React, { useContext, useState } from 'react';
import CartProducts from 'components/cart/CartProducts';
import { CartContext } from 'contexts/CartContext';
import { Link } from 'react-router-dom';
import NavBar from "components/common/NavBar.js";
import { Container, Button, Col, Modal, ModalBody, FormText } from "reactstrap";
import Footer from "components/common/Footer.js";
import CartTotal from "components/cart/CartTotal.js"
import CartNavBar from 'components/cart/CartNavBar.js';

const CartPage = () => {

    const { total, cartItems, itemCount, clearCart, checkout, handleCheckout } = useContext(CartContext);
    const [modal, setModal] = useState(false);

    document.documentElement.classList.remove("nav-open");

    const toggleModal = (product) => {
        setModal(!modal);
    };

    return (
        <>
            <CartNavBar />         

            <div className="main">

                <div className="section">
                    <Container>
                        <div >
                            <div className="row no-gutters p-1">
                                <Col md="9" className="p-1">
                                    {
                                        cartItems.length > 0 && !checkout ?
                                            <ul className="list-unstyled listing">
                                                <CartProducts />
                                            </ul> :
                                            <>
                                                <h2 class=" text-center">
                                                    Your cart is empty </h2>
                                                <center>
                                                    <br /><br />
                                                    <Link to="/restaurant" className="btn btn-timmy btn-round">ORDER FIRST</Link>
                                                </center>
                                            </>
                                    }
                                </Col>
                                {
                                    cartItems.length > 0 &&
                                    <CartTotal />
                                }
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            < Footer />
        </>
    );
}

export default CartPage;