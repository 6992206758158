import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { CartContext } from 'contexts/CartContext';
import { AuthContext } from "contexts/AuthContext";
import { isObjectEmpty } from 'helpers/utils';
import Button from "reactstrap/lib/Button";

function ShopNavBar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const { itemCount } = React.useContext(CartContext);
  const { isAuthUser, user, logout } = React.useContext(AuthContext);
  const [supportsPWA, setSupportsPWA] = React.useState(false);
  const [promptInstall, setPromptInstall] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [modalMsg, setModalMsg] = React.useState('');

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };
  const toggleModal = (msg) => {
    setModalMsg(msg);
    setModal(!modal);
  };
  const downloadApp = (evt) => {
    toggleModal()
  }
  const handler = e => {
    e.preventDefault();
    console.log("we are being triggered :D");
    setSupportsPWA(true);
    setPromptInstall(e);
  };

  React.useEffect(() => {

    window.addEventListener("beforeinstallprompt", handler);

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggleModal()}>
        <ModalHeader>Download on IOS</ModalHeader>
        <ModalBody className="text-center">
          <img alt="..." width="100%" src="ios.jpg" />
        </ModalBody>
      </Modal>
      <Container>
        <div className="navbar-translate">
          <ButtonGroup>

            <Button size="sm" href="/shops" className="btn-timmy  float-left"><i className="nc-icon nc-minimal-left" /></Button>
            <Button size="sm" href="/cart" className="btn-timmy btn-round float-left">   {itemCount}  <i className="nc-icon nc-cart-simple" /></Button>
          </ButtonGroup>
          <ButtonGroup>

            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>

          </ButtonGroup>

        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>

            {isAuthUser ?
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle caret nav>
                  <i className="nc-icon nc-circle-10" /> {!isObjectEmpty(user.fullName) ? user.fullName : user.userName}
                </DropdownToggle>
                <DropdownMenu right>

                  <DropdownItem onClick={logout}>
                    Log out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              :
              <NavItem>
                <NavLink to="/login" tag={Link}>
                  <i className="nc-icon nc-circle-10" /> LOG IN
            </NavLink>
              </NavItem>
            }
            <NavItem>
              <NavLink to="/cart" tag={Link}>
                <i className="nc-icon nc-cart-simple" /> ( {itemCount} ) CART</NavLink>

            </NavItem>
            {isAuthUser ?
              <>
                <NavItem>
                  <NavLink to="/myorders" tag={Link}>
                    <i className="nc-icon nc-paper" /> My Orders</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/myaccount" tag={Link}>
                    <i className="nc-icon nc-settings" /> Settings</NavLink>
                </NavItem>

              </>
              :
              ''}
            <NavItem>
              <NavLink onClick={downloadApp} >
                <i className="nc-icon nc-mobile" /> IOS Download
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ShopNavBar;
