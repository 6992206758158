import React, { useContext } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { Button, Col, Row } from "reactstrap";
import { formatNumber } from 'helpers/utils';
import { FormText, Modal, ModalBody, FormGroup } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { getVoucher, getDiscount } from 'helpers/API';
import { ERROR_REQUIRED } from "constants/index"
import { useHistory } from "react-router-dom";
import { AuthContext } from 'contexts/AuthContext';

const CartTotal = () => {

    const { logout } = React.useContext(AuthContext);
    const { total, itemCount, clearCart, addDiscount, shopId, addVoucher, setDeliveryFee, setDeliveryOption, availableForPickup, minimumOrderAmt } = useContext(CartContext);
    const [grandTotal, setGrandTotal] = React.useState('');

    const [voucherValue, setVoucherValue] = React.useState('');
    const [voucherCd, setVoucherCd] = React.useState('');
    const [voucherErr, setVoucherErr] = React.useState('');

    const [discountValue, setDiscountValue] = React.useState('');

    const [deliveryPay, setDeliveryPay] = React.useState(0);
    const [deliveryOpt, setDeliveryOpt] = React.useState('Delivery');

    const [serviceFee, setServiceFee] = React.useState(0);

    const [modal, setModal] = React.useState(false);
    const [modalMsg, setModalMsg] = React.useState('');
    const history = useHistory();

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        initTotal();
    }, [total]);

    const toggleModal = (msg) => {
        setModalMsg(msg);
        setModal(!modal);
    };

    const handleVoucherCdChange = (event) => {
        setVoucherCd(event.target.value);
    }

    // const handleDeliveryOptionChange = event => {
    //     setDeliveryOpt(event.target.value);
    //     if (event.target.value === 'Pickup') {

    //         setGrandTotal(Number(grandTotal) - Number(deliveryFee))
    //         setDeliveryPay(0)

    //     } else {
    //         setGrandTotal(Number(grandTotal) + Number(deliveryFee))
    //         setDeliveryPay(deliveryFee)
    //     }
    // }
    const initTotal = () => {

        // setGrandTotal(Number(total) + Number(deliveryFee));

        getDiscount(shopId, Number(total))
            .then(res => {

                setDeliveryPay(res.deliveryFee);
                if (res.discount.discountId) {

                    // if (Number(total) >= Number(res.minimumOrder)) {
                    //     const value = getDiscountValue(res);
                    //     setDiscountValue(value);
                    //     addDiscount(value);

                    //     const serviceFees = res.serviceFee;
                    //     setServiceFee(serviceFees);

                    //     const afterDiscount = Number(total) + Number(serviceFees) - Number(value);
                    //     setGrandTotal(Number(afterDiscount) + Number(res.deliveryFee));
                    // } else {
                    //     addDiscount(0);
                    //     setServiceFee(res.serviceFee)
                    //     setGrandTotal(Number(total) + Number(res.deliveryFee));
                    // }


                    const value = getDiscountValue(res.discount);
                    setDiscountValue(value);
                    addDiscount(value);

                    const serviceFees = res.serviceFee;
                    setServiceFee(serviceFees);

                    const afterDiscount = Number(total) + Number(serviceFees) - Number(value);
                    setGrandTotal(Number(afterDiscount) + Number(res.deliveryFee));

                } else {
                    addDiscount(0);
                    setServiceFee(res.serviceFee)

                    setGrandTotal(Number(total) + Number(res.deliveryFee) + Number(res.serviceFee));
                }

                setLoading(false);
            }).catch(error => {
                if (error.message && error.message.includes('out of bounds')) {
                    alert('There\'s something wrong with your address. Please make sure to pin your address to your exact location and save')
                    history.push('/updateAddress');
                }
                if (!error.message == 'Unauthorized') {
                    logout();
                    history.push('/login');
                }
                if (!error.message) {
                    alert('Please make sure to pin your address to your exact location and save')
                    history.push('/updateAddress');
                }


            });
    }

    const findVoucher = () => {
        if (voucherCd) {
            getVoucher(shopId, voucherCd)
                .then(res => {

                    if (Number(total) >= Number(res.minimumOrder)) {
                        let value = getVoucherValue(res);
                        setGrandTotal(grandTotal - value);
                        setVoucherValue(value);
                        addVoucher(voucherCd);
                        toggleModal('Voucher Applied')
                        setVoucherErr('');
                    } else {
                        toggleModal('Voucher cannot be applied. Minimum order is ' + formatNumber(res.minimumOrder));
                    }

                }).catch(error => {
                    setVoucherErr(error.message);
                });
        } else {
            toggleModal('You have to enter a voucher');
        }
    }

    const getVoucherValue = (voucher) => {
        if (voucher.type === 'Numeric') {
            return voucher.value;
        }
        if (voucher.type === 'Percentage') {
            return (voucher.value / 100) * total;
        }
    }

    const getDiscountValue = (discount) => {
        if (discount.type === 'Numeric') {
            return discount.value;
        }
        if (discount.type === 'Percentage') {
            return (discount.value / 100) * total;
        }
    }

    const pay = () => {
        if (total < minimumOrderAmt) {
            toggleModal('Minimum order amount for this shop is ' + formatNumber(minimumOrderAmt) + '. Add some more!');
            return;
        }

        setDeliveryFee(deliveryPay);
        setDeliveryOption(deliveryOpt);
        history.push('/pay')
    }

    return (
        <Col md="4" className="p-1">
            <div className="card card-body text-muted">
                <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggleModal()}>
                    <ModalBody className="text-center">
                        <i className="nc-icon nc-alert-circle-i text-info align-middle" /> &nbsp;  {modalMsg}
                    </ModalBody>
                </Modal>
                {/* {availableForPickup ?
                    <Row>
                        <Col md="12" xs="12">
                            <Label>Choose one</Label>
                            <FormGroup className="mb-0" check >
                                <Label check >
                                    <Input type="radio" value="Delivery" onClick={handleDeliveryOptionChange} name="option" checked={deliveryOpt == 'Delivery' ? true : false} /> Delivery
                                </Label>
                                <Label check >
                                    <Input type="radio" value="Pickup" onClick={handleDeliveryOptionChange} name="option" /> Pick Up
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    : ''} */}
                <Row>
                    <Col xs="10">
                        <Label>Do you have a voucher?</Label>
                        <Input size="sm" type="text" placeholder="Enter voucher" onChange={handleVoucherCdChange} value={voucherCd} />
                        {voucherErr ? <FormText color="danger">{voucherErr}</FormText> : ''}
                        <Button className="float-right" onClick={() => findVoucher()} size="sm">Apply</Button>
                    </Col>
                </Row>
                {!availableForPickup ? <small className="mb-1 text-danger">Note: Available for delivery only</small> : ''}
                <Row>
                    <Col xs="6">
                        <small className="mb-1">Number of items:</small>
                    </Col>
                    <Col xs="6">
                        <small className="mb-1">{itemCount}</small>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <small className="mb-1">Discount</small>
                    </Col>
                    <Col xs="6">
                        <small className="mb-1">{discountValue ? <> - {formatNumber(discountValue)} </> : 'No discount'}</small>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <small className="mb-1">Voucher &nbsp;
                            {voucherValue ? <Label className="label label-warning">Applied</Label> : ''}
                        </small>
                    </Col>
                    <Col xs="6">
                        <small className="mb-1 text-muted">{voucherValue ? <> - {formatNumber(voucherValue)} </> : 'No voucher applied'}</small>
                    </Col>
                </Row>
                {serviceFee ?
                    <Row>
                        <Col xs="6">
                            <small className="mb-1">Service Fee:</small>
                        </Col>
                        <Col xs="6">
                            <small className="mb-1"> + {formatNumber(serviceFee)}</small>
                        </Col>
                    </Row>

                    : ''}
                <Row>
                    <Col xs="6">
                        <small className="mb-1">Delivery Fee:</small>
                    </Col>
                    <Col xs="6">
                        <small className="mb-1"> + {formatNumber(deliveryPay)}</small>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <small >Total:</small>
                    </Col>
                    <Col xs="6">
                        <small >{formatNumber(total)}</small>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <h6 className="mt-1">To Pay:</h6>
                    </Col>
                    <Col xs="6">
                        {loading ?
                            <img width="20%" alt="Loading" className="img-responsive mr-auto ml-auto mb-0" src="assets\img\loading2.gif" />
                            :
                            <h6 className="mt-1">{formatNumber(grandTotal)}</h6>
                        }

                    </Col>
                </Row>
                <hr color="success" />
                <div className="text-center">
                    <Button disabled={loading} onClick={() => pay()} color="success">CHECKOUT</Button>
                    <Button
                        color="default"
                        onClick={clearCart}>CLEAR</Button>
                </div>
            </div>
        </Col>
    );
}

export default CartTotal;