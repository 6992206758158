import ProductsContext from "contexts/ProductsContext.js";
import React, { useContext, useState } from 'react';
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  Container,
  Row, FormText,
  Col,
  FormGroup, InputGroupText, InputGroup, InputGroupAddon, Alert,
} from "reactstrap";
import ProductsTab from "./ProductsTab.js";
import ReviewsTab from "./ReviewsTab.js"
import ReactDatetime from "react-datetime";
import moment from 'moment';

const ProfilePageSection = (props) => {

  const { shop } = useContext(ProductsContext);
  const [activeTab, setActiveTab] = useState("2");
  const [scheduledDate, setScheduledDate] = React.useState(new Date());

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const handleDateChange = event => {
    setScheduledDate(event._d);
    alert("Closed slots for selected date.")
  }

  const futureDates = (current) => {
    let yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  return (
    <div className="section profile-content">
      <Container>
        <div className="owner">
          < br />
          <div className="name">
            <h6 className="description">{shop ? shop.shopName : ''}
              <span className="text-warning">&nbsp;({shop ? shop.rating: ''}/5 Rating)</span><small> {shop ? shop.preparationTime: 45} min</small></h6>
              
          </div>
          <label className="label label-default mr-1">
          {shop ? shop.street: ''}, {shop ? shop.brgy: ''} , {shop ? shop.city: ''} 
          </label >
        </div>

        <Row>
          <Col className="ml-auto mr-auto text-center" md="9">
            <p>
              {shop? shop.description: ''}
            </p>
            {!shop.isReadyToAcceptOrder? <h6 className="text-danger">Temporarily closed  </h6> : '' }

          </Col>
        </Row>
        <div className="nav-tabs-navigation mb-2">
          <div className="nav-tabs-wrapper">
            <Nav role="tablist" tabs>

              <NavItem className="p-0">
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => {
                    toggle("2");
                  }} >
                  items&nbsp;
                    <small className="nc-icon nc-favourite-28 align-middle text-danger " />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  reviews&nbsp;
                  </NavLink>
              </NavItem>
            </Nav>
          </div>

        </div>
        {/* Tab panes */}
        <TabContent className="reviews" activeTab={activeTab}>
          <ProductsTab/>
          <ReviewsTab />
        </TabContent>
      </Container>
    </div>
  )

}
export default ProfilePageSection;
