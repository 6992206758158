import React, { useContext } from 'react';

import { Button, Card, Form, Input, FormGroup, InputGroupText, Row, Col, Label, InputGroup, InputGroupAddon, Modal, ModalBody, FormText } from "reactstrap";
import { CartContext } from 'contexts/CartContext';
import { pay, getCurrentUser } from 'helpers/API';
import { ERROR_REQUIRED } from "constants/index"
import { AuthContext } from "contexts/AuthContext";
import { useHistory } from "react-router-dom";
import moment from 'moment';

const Information = (disabled) => {
    const { total, cartItems, shopId, shopName, shopImage, clearCart, deliveryFee, addVoucher } = useContext(CartContext);
    const { success, logout } = React.useContext(AuthContext);

    const { user } = React.useContext(AuthContext);

    const [modal, setModal] = React.useState(false);
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [modalMsg, setModalMsg] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    const [referenceNumber, setReferenceNumber] = React.useState('');
    const [scheduledDate, setScheduledDate] = React.useState('');
    const [time, setTime] = React.useState(moment(new Date(), 'h:mm:ss A')
        .add(0, 'seconds')
        .add(45, 'minutes')
        .format('LTS'));
    const [isCompleted, setIsCompleted] = React.useState(false);
    const [fullName, setFullName] = React.useState('');
    const [fullNameErr, setFullNameErr] = React.useState('');

    // Inputs
    const [contactNumber, setContactNumber] = React.useState('');
    const [contactNumberError, setcontactNumberError] = React.useState('');

    const [houseNum, setHouseNum] = React.useState('');

    const [street, setStreet] = React.useState('');
    const [streetError, setStreetError] = React.useState('');

    const [brgy, setBrgy] = React.useState('');
    const [brgyError, setBrgyError] = React.useState('');

    const [city, setCity] = React.useState('');
    const [cityError, setCityError] = React.useState('');

    const [landmark, setLandmark] = React.useState('');

    const [notes, setNotes] = React.useState('');
    const [locationAddress, setLocationAddress] = React.useState('');

    const [deliveryOption, setDeliveryOption] = React.useState('Delivery');

    const history = useHistory();

    const handleFullNameChange = event => {
        setFullName(event.target.value);
        setFullNameErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)

    }
    const handleContactNumberChange = event => {
        setContactNumber(event.target.value.replace(/[^\d+\.?\d*]/, ''));
        setcontactNumberError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
        setcontactNumberError(event.target.value.length > 0 && event.target.value.length == 10 ? '' : 'Must be a 10-digit number')
    }
    const handleScheduledDateChange = event => {
        setScheduledDate(event._d);
    }
    const handlePreferredTimeChange = event => {
        setTime(event._d);
    }
    const handleHouseNumChange = event => {
        setHouseNum(event.target.value);
    }
    const handleStreetChange = event => {
        setStreet(event.target.value);
        setStreetError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }
    const handleBrgyChange = event => {
        setBrgy(event.target.value);
        setBrgyError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }
    const handleCityChange = event => {
        setCity(event.target.value);
        setCityError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }
    const handleLandmarkChange = event => {
        setLandmark(event.target.value);
    }
    const handleNotesChange = event => {
        setNotes(event.target.value);
    }
    const handleDeliveryOptionChange = event => {
        setDeliveryOption(event.target.value);
    }

    React.useEffect(() => {

        setLoading(true);
        let scheduledDate = cartItems[cartItems.length - 1] ? cartItems[cartItems.length - 1].scheduledDate : '';
        setScheduledDate(scheduledDate ? scheduledDate : '');

        getCurrentUser().then(response => {
            success(response);
            setFullName(response.fullName ? response.fullName : '');
            setHouseNum(response.address && response.address.houseNum ? response.address.houseNum : '');
            setStreet(response.address && response.address.street ? response.address.street : '');
            setBrgy(response.address && response.address.brgy ? response.address.brgy : '');
            setCity(response.address && response.address.city ? response.address.city : '');
            setLandmark(response.address && response.address.landmark ? response.address.landmark : '');
            setContactNumber(response.contactNumber ? response.contactNumber : '');

            setLoading(false);

        }).catch(error => {
            if ((error.message).includes('unauthorized error')) {
                logout();
                history.push('/login');
            }
        });

    }, []);

    const futureDates = (current) => {
        let yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    }

    const toggleModal = (order) => {
        setModal(!modal);
        setReferenceNumber(order.referenceNumber);

        if (isCompleted) {
            history.push('/waiting');
            clearCart();
        }
    };

    const toggleLoadingModal = (msg) => {
        setModalMsg(msg);
        setLoadingModal(!loadingModal);

    };

    const validateInputs = () => {
        let hasErrors = false;
        if (!fullName) {
            setFullNameErr(ERROR_REQUIRED);
            hasErrors = true;
        }
        if (!contactNumber) {
            setcontactNumberError(ERROR_REQUIRED);
            hasErrors = true;
            alert('Please input your contact number in order to proceed');

        }
        if (contactNumber.length != 10) {
            setcontactNumberError('Must be a 10-digit number');
            alert('Contact number must be a 10-digit number')
            hasErrors = true;
        }
        // if (!brgy) {
        //     setBrgyError(ERROR_REQUIRED);
        //     hasErrors = true;
        //     alert('Please input brgy in order to proceed');

        // }
        // if (!street) {
        //     setStreetError(ERROR_REQUIRED);
        //     hasErrors = true;
        //     alert('Please input street in order to proceed');

        // }
        // if (!city) {
        //     setCityError(ERROR_REQUIRED);
        //     hasErrors = true;
        //     alert('Please input city in order to proceed');
        // }
        // if (city && !city.includes('bayugan') && !city.includes('Bayugan')) {
        //     toggleLoadingModal('Unable to proceed. We are only available within Bayugan proper')
        //     hasErrors = true;
        // }
        return hasErrors;
    }

    const createOrder = () => {

        if (cartItems.length === 0) {
            alert('Your cart is empty!');
            return;
        }
        const timeFormat = 'HH:mm';
        const dateFormat = 'YYYY/MM/DD';

        const address = {
            houseNum: houseNum ? houseNum : '',
            // street: street,
            // brgy: brgy,
            // city: city,
            landmark: landmark ? landmark : '',
            locationAddress: user.address.locationAddress,
            latitude: user.address.latitude,
            longitude: user.address.longitude

        }

        if (!validateInputs()) {
            const body = {
                'cartItems': cartItems,
                'totalPayment': total,
                'scheduledDate': moment(scheduledDate).format(dateFormat),
                'scheduledTime': moment(time).format(timeFormat),
                'notes': notes,
                'fullName': fullName,
                'address': address,
                'contactNumber': contactNumber,
                'shopId': shopId,
                'shopName': shopName,
                'shopImage': shopImage,
                'deliveryFee': cartItems[0].deliveryFee ? cartItems[0].deliveryFee : 0,
                'deliveryOption': cartItems[0].deliveryOption ? cartItems[0].deliveryOption : 'Delivery',
                'voucher': cartItems[0].voucher ? cartItems[0].voucher : null,
                'discount': cartItems[0].discount ? cartItems[0].discount : 0
            };
            toggleLoadingModal(<>  Processing order <img width="10%" src="assets/img/loading2.gif" alt="Card image cap" /></>);

            pay(JSON.stringify(body))
                .then(res => {
                    setIsCompleted(true);
                    setLoadingModal(false);
                    toggleModal(res);

                }).catch(error => {
                    if ((error.message).includes('Voucher')) {
                        addVoucher(null)
                        alert(error.message);
                        redirect();
                    } else {

                        alert(error.message);
                        redirect();
                    }
                });
        }

    }
    const redirect = () => {
        history.push('/restaurants');
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggleModal}>
                <div className="modal-header">
                    <button
                        aria-label="Close"
                        className="close"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    <h5 className="modal-title text-center">
                        Confirmation </h5>
                </div>
                <ModalBody >
                    Your order is now confirmed. Reference Number #{referenceNumber}.
                    Thank you!
                    <br />
                </ModalBody>
                <div className="modal-footer">
                    <Button onClick={toggleModal} className="btn-link" color="danger" type="button">
                        Close
              </Button>
                </div>
            </Modal>
            <Modal className="modal-dialog-centered" isOpen={loadingModal} toggle={() => toggleLoadingModal()}>
                <ModalBody >
                    {modalMsg}
                </ModalBody>
            </Modal>
            {loading ?
                <div className="section section-white text-center">
                    <img width="35%" alt="Loading" className="img-responsive timmy-centered" src="assets\img\loading.gif" />
                </div>
                :
                <Col className="ml-auto mr-auto p-1 m-5" lg="4" xs="11">
                    <Card className="card card-body mt-3 ml-auto mr-auto bg-light text-dark">
                        <img alt="..." className="mx-auto" width="20%" src="assets/img/mycook-3.png" />
                        <h3 className="mx-auto">Review Information</h3>
                        <Row className="no-gutters">
                        <Col lg="12" xs="12">
                                <label>Address</label>
                                <Input type="text" disabled value={landmark} value={user.address.locationAddress} placeholder="Landmark" />
                            </Col>
                            <Col lg="12" xs="12">
                                <label>Landmark</label>
                                <Input type="text"  value={landmark} onChange={handleLandmarkChange} placeholder="Landmark" />
                            </Col>
                            <Col md="6" xs="12">
                                <label>Contact Person</label>
                                <Input type="text" placeholder="Full Name" value={fullName} onChange={handleFullNameChange} />
                                <FormText className="text-danger">{fullNameErr ? fullNameErr : ''}</FormText>

                            </Col>
                            <Col md="6" xs="12">
                                <label>Contact Number</label>
                                <InputGroup>
                                <InputGroupAddon addonType="prepend">+63</InputGroupAddon>
                                <Input type="number" value={contactNumber} onChange={handleContactNumberChange} />
                            </InputGroup>
                                <FormText className="text-danger">{contactNumberError ? contactNumberError : ''}</FormText>

                                {/* <Input type="number" disabled value={'0'+contactNumber} onChange={handleContactNumberChange} placeholder="10 digit #" /> */}
                            </Col>
                        </Row>
                        {/* <Row className="no-gutters">
                        <Col lg="6" xs="6">
                            <label>Preferred Time</label>
                            <FormGroup>
                                <InputGroup className="date" id="datetimepicker">
                                    <ReactDatetime dateFormat={false} value={time} onChange={handlePreferredTimeChange} />
                                    <InputGroupText  >
                                        <span className="nc-icon nc-time-alarm"></span>
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col lg="6" xs="6">
                            <label>Delivery Date</label>
                            <FormGroup>
                                <InputGroup className="date" id="datetimepicker">
                                    <ReactDatetime timeFormat={false} value={convertToDate(scheduledDate)}
                                        inputProps={{ disabled: true }}
                                        closeOnSelect={true} />
                                   
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row> */}
                        <Row className="no-gutters">
                            {/* <Col lg="4" xs="4">
                                <label>House # </label>
                                <Input type="text" value={houseNum} onChange={handleHouseNumChange} placeholder="House #" />
                            </Col>
                            <Col lg="8" xs="8">
                                <label>Street</label>
                                <Input type="text" value={street} onChange={handleStreetChange} placeholder="Street" />
                                <FormText className="text-danger">{streetError ? streetError : ''}</FormText>
                            </Col>
                            <Col lg="6" xs="6">
                                <label>Brgy</label>
                                <Input type="text" value={brgy} onChange={handleBrgyChange} placeholder="Brgy." />
                                <FormText className="text-danger">{brgyError ? brgyError : ''}</FormText>
                            </Col>
                            <Col lg="6" xs="6">
                                <label>City</label>
                                <Input type="text" value={city} onChange={handleCityChange} placeholder="City/Municipality" />
                                <FormText className="text-danger">{cityError ? cityError : ''}</FormText>
                            </Col> */}
                         
                            
                        </Row>
                        <label>Other Notes to the Rider</label>
                        <Input type="textarea" onChange={handleNotesChange} />

                        <Button onClick={createOrder} block className="btn-round mt-2 mb-0 btn-timmy">
                            Complete </Button>
                       
                        <Button onClick={redirect} block className="btn-link" >
                            Back </Button>
                        <FormText className="mr-auto ml-auto" color="danger">Note: Cash on delivery only. Please prepare exact amount for faster transaction. </FormText>
                        <br />
                    </Card>
                </Col>
            }
        </>
    );
}

export default Information;
