import React from "react";

import NavBar from "components/search/SearchNavBar.js";
import Footer from "components/common/Footer.js";
import Profile from "components/user/Profile.js";
import { Container, Row } from "reactstrap";

function UserProfilePage() {
    document.documentElement.classList.remove("nav-open");
  
    return (
        <>
            <NavBar />
            <div className="main">
                <br />
                <div className="section">
                    <Container>
                        <Row>
                            <Profile />
                        </Row>
                    </Container>
                </div>

            </div>
            <Footer />
        </>
    );
}

export default UserProfilePage;
