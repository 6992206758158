import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import LandingNavBar from "components/common/LandingNavBar.js";
import LandingPageHeader from "components/common/LandingPageHeader.js";
import Footer from "components/common/Footer.js";
import FormText from "reactstrap/lib/FormText";
import CardHeader from "reactstrap/lib/CardHeader";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import { ERROR_REQUIRED } from "constants/index"
import { sendRiderApplication } from "helpers/API"
import NavBar from "components/common/NavBar";
import SearchNavBar from "components/search/SearchNavBar";

function RiderPage() {

  let pageHeader = React.createRef();

  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');

  const [preferredTime, setPreferredTime] = React.useState('');
  const [preferredTimeError, setPreferredTimeError] = React.useState('');

  const [vehicle, setVehicle] = React.useState('');
  const [vehicleError, setVehicleError] = React.useState('');

  const [contactNumber, setContactNumber] = React.useState('');
  const [contactNumberError, setContactNumberError] = React.useState('');

  const [street, setStreet] = React.useState('');
  const [streetError, setStreetError] = React.useState('');

  const [brgy, setBrgy] = React.useState('');
  const [brgyError, setBrgyError] = React.useState('');

  const [city, setCity] = React.useState('');
  const [cityError, setCityError] = React.useState('');

  const [modal, setModal] = React.useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handlePreferredTimeChange = (event) => {
    setPreferredTime(event.target.value);
    setPreferredTimeError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handleVehicleTypeChange = (event) => {
    setVehicle(event.target.value);
    setVehicleError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handleContactNumberChange = (event) => {
    setContactNumber(event.target.value.replace(/[^\d+\.?\d*]/, ''));
    setContactNumberError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }
  const handleStreetChange = event => {
    setStreet(event.target.value);
    setStreetError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }
  const handleBrgyChange = event => {
    setBrgy(event.target.value);
    setBrgyError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }
  const handleCityChange = event => {
    setCity(event.target.value);
    setCityError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const validateInputs = () => {
    let hasErrors = false;
    if (!name) {
      setNameError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!contactNumber) {
      setContactNumberError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!email) {
      setEmailError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!vehicle) {
      setVehicleError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!preferredTime) {
      setPreferredTimeError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!street) {
      setStreetError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!brgy) {
      setBrgyError(ERROR_REQUIRED);
      hasErrors = true;
    }
    if (!city) {
      setCityError(ERROR_REQUIRED);
      hasErrors = true;
    }
    return hasErrors;
  }

  const submit = () => {

    if (!validateInputs()) {
      const address = {
        street: street,
        brgy: brgy,
        city: city
      }

      const body = {
        riderName: name,
        contactNumber: contactNumber,
        emailAddress: email,
        address: address,
        vehicleType: vehicle,
        preferredTime: preferredTime
      }

      sendRiderApplication(JSON.stringify(body)).then(response => {

        console.log(response);
        toggleModal();
        reset();
      })
    }

  }

  const reset = () => {
    setName('');
    setContactNumber('');
    setEmail('');
    setPreferredTime('');
    setStreet('');
    setBrgy('');
    setCity('');
  }


  return (
    <>
      <SearchNavBar />
      {/* <NavBar /> */}

      <div className="section ">
        <Container>
          <Row className="mt-5">
            <Col className="mr-auto ml-auto" md="4" xs="12">
              <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader>

                </ModalHeader>
                <ModalBody>
                  <h4 className="mt-0">Thank you!</h4> <br />
                  <small>
                    Your application has been submitted. Please expect a call from us within 3-5 business days. If you have questions, drop us an email on contact@im-timmy.com. Have a good day!
                    </small>
                  {/* <Button color="success">Close</Button> */}
                </ModalBody>
              </Modal>
              <Card>
                <CardHeader>
                  <h3 className="text-muted text-center">Become a Rider</h3>
                </CardHeader>
                <CardBody>
                  <li>Must be 18 and above </li> <br />
                  <li>Has own vehicle, driver's license, and mobile device</li><br />
                  <li>Manage delivery requests via Timmy Rider App</li> <br />
                  <li>Commission-based </li><br />
                  <li>Delivery service of atleast 4 hrs /day with flexible start and end time </li><br />
                </CardBody>
              </Card>
            </Col>
            <Col className="mr-auto ml-auto" md="8">
              <Card>
                <CardHeader>
                  <h3 className="text-muted text-center">Apply Here</h3></CardHeader>
                <CardBody>
                  <FormGroup>
                    <Row>
                      <Col md="12" >
                        <Label>Name</Label>
                        <Input type="text" placeholder="Name" value={name} onChange={handleNameChange}></Input>
                        <FormText className="text-danger">{nameError ? nameError : ''}</FormText>
                      </Col>
                      <Col md="6">
                        <Label>Contact Number</Label>
                        <Input type="text" maxlength="11" placeholder="11 digits" value={contactNumber} onChange={handleContactNumberChange}></Input>
                        <FormText className="text-danger">{contactNumberError ? contactNumberError : ''}</FormText>
                      </Col>
                      <Col md="6">
                        <Label>Email Address</Label>
                        <Input type="text" maxlength="11" placeholder="timmy@mail.com" value={email} onChange={handleEmailChange}></Input>
                        <FormText className="text-danger">{emailError ? emailError : ''}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Label for="dropdown">Vehicle</Label>
                        <Input type="select" name="select" value={vehicle} onChange={handleVehicleTypeChange}  >
                          <option name="vehicle" value="Motorcycle">Motorcycle</option>
                          <option name="vehicle" value="Bike">Bike</option>
                        </Input>
                      </Col>
                      <Col md="6">
                        <Label>Preferred time</Label>
                        <Input type="text" placeholder="9 am to 12 pm" value={preferredTime} onChange={handlePreferredTimeChange}></Input>
                        <FormText className="text-danger">{preferredTimeError ? preferredTimeError : ''}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <Label>Address</Label>
                        <Input type="text" placeholder="Landmark, Bldg, St" value={street} onChange={handleStreetChange}></Input>
                        <FormText className="text-danger">{streetError ? streetError : ''}</FormText>
                      </Col>
                      <Col md="4">
                        <Label>Brgy</Label>
                        <Input type="text" placeholder="Brgy" value={brgy} onChange={handleBrgyChange}></Input>
                        <FormText className="text-danger">{brgyError ? brgyError : ''}</FormText>

                      </Col>
                      <Col md="4">
                        <Label>City</Label>
                        <Input type="text" placeholder="City" value={city} onChange={handleCityChange}></Input>
                        <FormText className="text-danger">{cityError ? cityError : ''}</FormText>
                      </Col>
                    </Row>
                    <Button onClick={() => submit()} className="float-right mt-1" color="success"> Apply </Button>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>
      <Footer />
    </>
  );
}

export default RiderPage;
