import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
import * as serviceWorker from './serviceWorker';
import { Button, Row, Col } from "reactstrap";
import { initializeFirebase } from 'pwa/push-notification';

ReactDOM.render(
  <><App />
 

  </>,
  document.getElementById("root")

);
serviceWorker.register();
initializeFirebase();
