import React, { useState } from "react";
var DatePicker = require("reactstrap-date-picker");

const Calendar = () => {
    let currentDate = new Date().toISOString();
    const [value, setValue] = React.useState(currentDate);
    const handleDateChange = (value, formattedValue) => {
        setValue(value);
    }

    return (
        <>
            <DatePicker id="datepicker"
                value={value}
                onChange={(v, f) => handleDateChange(v, f)}
                minDate={currentDate} />

        </>

    );
};

export default Calendar;