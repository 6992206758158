export const API_BASE_URL = 'https://timmyexpress.com:8084';
export const IMAGE_URL = 'https://timmyexpress.com:3001/assets/img/shops/';
export const OAUTH2_REDIRECT_URI = 'https://timmyexpress.com/oauth2/redirect?path='

// export const API_BASE_URL = 'http://localhost:8080';
// export const IMAGE_URL = 'http://localhost:3001/assets/img/shops/';
// export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect?path='

export const ACCESS_TOKEN = 'accessToken';
export const ERROR_REQUIRED = '* Required Field';

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const FACEBOOK_PAGE = 'www.facebook.com';
