import React, { useContext } from 'react';

import {
  Button,
  TabPane,
  Row,
  Col,
  Modal,
  ModalBody,
  Label, FormGroup, InputGroupText, InputGroup, InputGroupAddon, Alert,

} from "reactstrap";
import Product from "components/shop/Product.js"
import { CartContext } from 'contexts/CartContext';
import { ProductsContext } from 'contexts/ProductsContext';
import FormText from 'reactstrap/lib/FormText';
import { formatNumber } from '../../helpers/utils';
import { isInCart } from '../../helpers/utils';
import moment from 'moment';
import ReactDatetime from "react-datetime";
import { getSlots } from 'helpers/API';
import { IMAGE_URL } from 'constants/index';
import { getProductsByShopId } from 'helpers/API';

const ProductsTab = () => {
  const { addProduct, cartItems, increase, clearCart } = useContext(CartContext);
  const { shop } = useContext(ProductsContext);
  const [modal, setModal] = React.useState(false);
  const [product, selectedItem] = React.useState({});
  const [scheduledDate, setScheduledDate] = React.useState(new Date());
  const [availableSlots, setAvailable] = React.useState(20);
  const [slots, setSlots] = React.useState({});
  const [msgModal, setMsgModal] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [noCategoryList, setNoCategoryList] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [list, setList] = React.useState([]);
  const [categories, setCategories] = React.useState([]);


  const futureDates = (current) => {
    let yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  const handleDateChange = event => {
    const format1 = 'YYYY/MM/DD';
    let formattedValue = moment(event).format(format1);
    loadSlots(formattedValue);
  }

  React.useEffect(() => {
    const format1 = 'YYYY/MM/DD';
    let formattedValue = moment(new Date()).format(format1);
    // loadSlots(formattedValue);
    loadProductsAndCategories();

    // const filter = list && list.filter(prod => !prod.category);
    // setNoCategoryList(filter)
  }, []);


  const loadProductsAndCategories = () => {
    // setLoading(true);
    getProductsByShopId(shop.shopId).then(res => {
      if (res) {
        const prdctList = res.products;
        const categryList = res.categories;

        setList(prdctList);
        setCategories(categryList);

        const filter = prdctList && prdctList.filter(prod => !prod.category);
        setNoCategoryList(filter);

        setLoading(false);
      }
    })
  }

  const loadSlots = (formattedValue) => {
    getSlots(shop.shopId).then(res => {

      // If no retrieved slots in backend
      if (res.shopId == 0) {
        setAvailable(0);
      } else {
        getDaySlot(formattedValue);
        setSlots(res.slots);
        setScheduledDate(formattedValue);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const getDaySlot = (dateValue) => {
    if (dateValue) {
      const fullDate = dateValue.replaceAll('/', '-');
      const dd = fullDate.substring(8, 10);
      const yyyymm = fullDate.substring(0, 7);

      if (!!slots) {
        const slots1 = slots[yyyymm];
        if (slots1) {
          setAvailable(slots1[dd].numberOfAvailableSlots);
        }
      }
      setScheduledDate(dateValue);
    }
  }

  const toggleModal = (product) => {
    initProduct(product);
    setModal(!modal);
    selectedItem(product);
  };

  const toggleMsgModal = (msg) => {
    setMsg(msg);
    setMsgModal(!msgModal);

    if (modal) {
      toggleModal(product)
    }
  };

  const initProduct = (product) => {
    product['shopId'] = !!product.shopId ? product.shopId : product['shopId'] = shop.shopId;
    product['shopName'] = !!product.shopName ? product.shopName : product['shopName'] = shop.shopName;
    product['addOn1'] = {};
    product['addOn2'] = {};
    product['addOn3'] = {};
    product['addOn4'] = {};
    product['addOn5'] = {};
    product['addOnsPrice'] = 0;
    product['scheduledDate'] = scheduledDate;
    product['displayImage'] = IMAGE_URL + product.shopId + '/' + product.image1;
    product['deliveryFee'] = shop.deliveryFee;
    product['hasDeliveryService'] = !!product.hasDeliveryService ? product.hasDeliveryService : product['hasDeliveryService'] = shop.hasDeliveryService;
    product['availableForPickup'] = shop.availableForPickup;
    product['minimumOrderAmt'] = shop.minimumOrderAmt ? shop.minimumOrderAmt : 0;



  }


  const isTheSameShop = product => {
    return !!cartItems.find(item => (item.shopId == product.shopId));
  }

  const addProd = product => {
    if (!shop.isReadyToAcceptOrder) {
      toggleMsgModal('Cannot add to cart. Shop is closed');
    } else if (product.variationList.length > 0 && !hasRequiredAddOns(product)) {
      // Check if any add on is selected
      toggleMsgModal('Oops! Cannot add to cart. You forgot to select some options for this item');

    } else {
      if (cartItems.length !== 0 && !isTheSameShop(product)) {
        alert('You have already selected from a different restaurant. If you continue your cart and selection will be removed.');
        clearCart();
      }

      if (isInCart(cartItems, product)) {
        increase(product);
      } else {
        addProduct(product);
      }
      toggleModal(product);
    }

  }

  const hasRequiredAddOns = product => {
    let hasAddOn = false;
    let addOnCount = 0;
    const variationLst = product.variationList.filter(variation => variation.type === 'Single');

    if (product['addOn1'].option) {
      addOnCount++;
    }
    if (product['addOn2'].option) {
      addOnCount++;
    }
    if (product['addOn3'].option) {
      addOnCount++;
    }
    if (product['addOn4'].option) {
      addOnCount++;
    }
    if (product['addOn5'].option) {
      addOnCount++;
    }
    if (addOnCount >= variationLst.length) {
      hasAddOn = true;
    }
    return hasAddOn;
  }

  return (
    <TabPane tabId="2" id="listing">

      {!loading ?
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Modal className="modal-dialog-centered" isOpen={msgModal} toggle={() => toggleMsgModal('')}>

              <ModalBody className="mt-0">
                <i className="nc-icon nc-alert-circle-i text-info align-middle" />
                &nbsp;{msg}
              </ModalBody>

            </Modal>
            <Modal isOpen={modal} toggle={toggleModal}>
              <div className="modal-header">
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={toggleModal}
                >
                  <span className="text-price" aria-hidden={true}>×</span>
                </button>
                <h5 className="modal-title text-center"
                  id="exampleModalLabel">
                  {product.name}
                </h5>
              </div>
              {product.image1 ? <img width="100%" src={product.displayImage} alt="Card image cap" /> : ''}
              <ModalBody >
                <p>{product.description}</p>
                <Product slots={slots} product={product} price={product.price} variationList={product.variationList} />
              </ModalBody>
              <div className="modal-footer">
                <Button onClick={() => addProd(product)} className="btn-link" color="danger" type="button">
                  Add to Cart
                </Button>
              </div>
            </Modal>

            <ul className="list-unstyled listing">
              {noCategoryList && noCategoryList.map((menuItem, index) => {
                const image = IMAGE_URL + shop.shopId + "/" + menuItem.image1;
                return (
                  <li key={menuItem.productId} onClick={() => {
                    toggleModal(menuItem);
                  }}>
                    <Row>
                      {menuItem.image1 ?
                        <>
                          <Col className="ml-auto mr-auto" lg="2" md="4" xs="6">
                            <img
                              alt="..."
                              className="img-fluid"
                              src={image} />
                          </Col>
                          <Col className="ml-auto mr-auto" lg="10" md="8" xs="6">
                            <Button close aria-label="Cancel"
                              onClick={() => {
                                toggleModal(menuItem);
                              }}>
                              <span className="text-price" aria-hidden> + </span>
                            </Button>
                            <h6 className="description">{menuItem.name} </h6>
                            <p>
                              {menuItem.description}
                            </p>
                            <Label className="label label-price"> {formatNumber(menuItem.price)}</Label>
                          </Col>
                        </>
                        :
                        <>
                          <Col className="ml-auto mr-auto " lg="10" md="8" xs="9">
                            <h6 className="description">{menuItem.name} </h6>
                            <small className="description">
                              {menuItem.description} <br />
                            </small>
                          </Col>
                          <Col className="ml-auto mr-auto" lg="2" md="4" xs="3">
                            <Button close aria-label="Cancel"
                              onClick={() => {
                                toggleModal(menuItem);
                              }}>
                              <span aria-hidden> + </span>
                            </Button>
                            <Label className="label label-price"> {formatNumber(menuItem.price)}</Label>
                          </Col>
                        </>
                      }

                    </Row>
                    <hr />
                  </li>
                )
              }
              )}

            </ul>
            {categories && categories.map(category => {
              const prodByCategory = list && list.filter(prod => prod.category && prod.category.name == category.name);

              if (prodByCategory.length > 0) {
                return (
                  <>

                    <h5 className="description mt-0"><i className="nc-icon nc-tile-56 align-middle text-timmy" />&nbsp;{category.name}</h5> <br />

                    {prodByCategory && prodByCategory.length > 0 && prodByCategory.map((menuItem, index) => {
                      const image = IMAGE_URL + shop.shopId + "/" + menuItem.image1;
                      return (
                        <>
                          <ul className="list-unstyled listing">
                            <li key={menuItem.productId} onClick={() => {
                              toggleModal(menuItem);
                            }}>
                              <Row>
                                {menuItem.image1 ?
                                  <>
                                    <Col className="ml-auto mr-auto" lg="2" md="4" xs="6">
                                      <img
                                        alt="..."
                                        className="img-fluid"
                                        src={image} />
                                    </Col>
                                    <Col className="ml-auto mr-auto" lg="10" md="8" xs="6">
                                      <Button close aria-label="Cancel"
                                        onClick={() => {
                                          toggleModal(menuItem);
                                        }}>
                                        <span className="text-timmy" aria-hidden> + </span>
                                      </Button>
                                      <h6 className="description">{menuItem.name} </h6>
                                      <p>
                                        {menuItem.description}
                                      </p>
                                      <Label className="label label-price"> {formatNumber(menuItem.price)}</Label>
                                    </Col>
                                  </>
                                  :
                                  <>
                                    <Col className="ml-auto mr-auto " lg="10" md="8" xs="9">
                                      <h6 className="description">{menuItem.name} </h6>
                                      <small className="description">
                                        {menuItem.description} <br />
                                      </small>
                                    </Col>
                                    <Col className="ml-auto mr-auto" lg="2" md="4" xs="3">
                                      <Button close aria-label="Cancel"
                                        onClick={() => {
                                          toggleModal(menuItem);
                                        }}>
                                        <span className="text-price" aria-hidden> + </span>
                                      </Button>
                                      <Label className="label label-price"> {formatNumber(menuItem.price)}</Label>
                                    </Col>
                                  </>
                                }

                              </Row>
                              <hr />
                            </li>
                          </ul>
                        </>
                      )
                    })}
                  </>

                )
              } else {
                return ('');
              }

            })}
          </Col>
        </Row>
        :
        <>
          <h4 className="text-center">Loading restaurant menu..
            <br />
            Please hang on for a sec
            <br />
            <h2>
              <i className="nc-icon nc-delivery-fast" />
            </h2>
          </h4>

        </>
      }
    </TabPane>
  )
}
export default ProductsTab;