import React, { useContext } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { formatNumber } from 'helpers/utils';
import { Button, Card, Label, Form, Input, Container, Row, Col, InputGroup, InputGroupAddon } from "reactstrap";
import { AuthContext } from "contexts/AuthContext";
import { getUserOrders } from 'helpers/API';
import { useHistory } from "react-router-dom";

import { IMAGE_URL } from 'constants/index';

const Waiting = () => {

    const { user } = React.useContext(AuthContext);
    const [activeOrders, setActiveOrders] = React.useState([]);
    const [currentlyPreparing, setCurrentlyPreparing] = React.useState([]);
    const [pendingDelivery, setPendingDelivery] = React.useState([]);
    const [forPickup, setForPickup] = React.useState([]);
    const [cancelledOrders, setCancelledOrders] = React.useState([]);
    const history = useHistory();
    const [time, setTime] = React.useState('');
    const [loading, setLoading] = React.useState('');

    React.useEffect(() => {
        setLoading(true);
        getUserOrders().then(res => {
            if (res) {
                const activeOrders = res.filter(order => (order.status === 'New'));
                setActiveOrders(activeOrders);

                const preparing = res.filter(order => (order.status === 'Accepted'));
                setCurrentlyPreparing(preparing);

                const ready = res.filter(order => (order.status === 'Delivery'));
                setForPickup(ready);

                const pendingDeliveries = res.filter(order => (order.status === 'InTransit'));
                setPendingDelivery(pendingDeliveries);

                const cancelledOrders = res.filter(order => (order.status === 'Cancelled'));
                setCancelledOrders(cancelledOrders);

                if (activeOrders.length === 0 && preparing.length === 0 && pendingDeliveries.length === 0 && ready.length === 0) {
                    history.push("/myorders")
                }

                setLoading(false);
            }
        },
            error => {
                setLoading(false);
                alert(error.message);
            });
    }, [time]);




    return (
        <>
            {loading ?
                <div className="section section-white text-center">
                    <Container>
                        <div className="row mt-5">
                            <Col className="ml-auto mr-auto mt-5" sm="12">
                                <br />
                                <img width="40%" alt="Loading" className="img-responsive" src="assets\img\loading.gif"></img>
                            </Col>
                        </div>
                    </Container>
                </div>
                :
                <div className="main ">
                    <br />
                    <div className="section">
                        <Col className="m-auto" sm="12" lg="8" md="8">
                            <div className="filter" />
                            <Col className="ml-auto mr-auto" lg="7">
                                <Card className="card card-body ml-auto mr-auto bg-light text-dark">
                                    {activeOrders.length == 0 ? <></>
                                        :
                                        <>
                                            <img width="8%" alt="Loading" className="img-responsive mr-auto ml-auto mb-0" src="assets\img\loading2.gif"></img>
                                            <h6>
                                                <i className="nc-icon nc-alert-circle-i align-middle" /> &nbsp; Waiting confirmation from merchant </h6>
                                            <br />
                                            <ul className="list-unstyled listing mb-0">
                                                {activeOrders && activeOrders.map((order) => {
                                                    return (
                                                        <li>
                                                            <Row>
                                                                <Col className="ml-auto mr-auto" lg="3" md="3" xs="3">
                                                                    <img
                                                                        alt="..."
                                                                        className="img-rounded img-responsive"
                                                                        src={IMAGE_URL + order.shop.shopId + '/' + order.shop.shopImage} />
                                                                </Col>
                                                                <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">
                                                                    <h6> {order.shop.shopName}</h6>

                                                                    {order.orderedItems && order.orderedItems.map((orderItem, i) => {
                                                                        const products = Object.keys(orderItem);

                                                                        return (
                                                                            <li className="mb-0">
                                                                                {products.map((product) => {
                                                                                    const addOns = orderItem[product] ? orderItem[product]['addOns'] : [];
                                                                                    return (
                                                                                        <small>
                                                                                            <li>{product} x {orderItem[product]['quantity']}</li>
                                                                                            <li>
                                                                                                <Row className="no-gutters">
                                                                                                    {addOns.map((variation) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <Col xs="8"> {variation.name} : {variation.value} &nbsp; </Col>
                                                                                                                <Col xs="4">  {formatNumber(variation.price)}  </Col>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                    <Col xs="8"> Item Price : </Col>
                                                                                                    <Col xs="3"> <Label color="warning" className="label label-warning"> {formatNumber(orderItem[product]['totalItemPrice'])}</Label></Col>
                                                                                                </Row>
                                                                                                <hr />
                                                                                            </li>
                                                                                        </small>
                                                                                    )
                                                                                })}
                                                                            </li>
                                                                        )
                                                                    })}
                                                                    <Row>
                                                                        <Col className="align-left font-weight-bold" lg="7" md="7" xs="7">Grand Total</Col>
                                                                        <Col className="align-left" lg="5" md="5" xs="5"> <Label className="label label-success">{formatNumber(order.totalPayment)}</Label></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="7" md="7" xs="7"><small> Delivery Option </small></Col>
                                                                        <Col className="align-left" lg="5" md="5" xs="5"> <small> {order.deliveryOption} </small></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="7" md="7" xs="7"><small> Delivery Fee</small></Col>
                                                                        <Col className="align-left" lg="5" md="5" xs="5"> <small> {formatNumber(order.deliveryFee)} </small></Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1"> Delivery Address </h6>
                                                                            <small> {order.customer.address.locationAddress ? order.customer.address.locationAddress + ', ' : ''} </small>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8">#{order.referenceNumber}</Col>
                                                                    </Row>

                                                                </Col>
                                                            </Row>
                                                            {order.rider && order.rider.riderName ?
                                                                <>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col lg="3" md="3" xs="3">
                                                                            <h2 className="float-right mt-0"> <i className="nc-icon nc-delivery-fast align-middle" /></h2>
                                                                        </Col>
                                                                        <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">

                                                                            <Row>
                                                                                <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1">Rider Assigned </h6>
                                                                                    <small> {order.rider.riderName} </small><br />
                                                                                    <small> 0{order.rider.contactNumber} </small>
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                                : ''}
                                                            <hr />
                                                        </li>

                                                    )
                                                })}
                                                <></>
                                            </ul>

                                        </>
                                    }

                                    {currentlyPreparing.length == 0 ? <></>
                                        :
                                        <>
                                            <img alt="..." className="mx-auto" width="50%" src="assets/img/loading-3.gif" />
                                            <h6 className="text-center"> Your order has been accepted by merchant. Preparing order..
                                            </h6>
                                            <hr />
                                            <ul className="list-unstyled listing mb-0">

                                                {currentlyPreparing && currentlyPreparing.map((order) => {
                                                    return (

                                                        <li>
                                                            <Row>
                                                                <Col className="ml-auto mr-auto" lg="3" md="3" xs="3">
                                                                    <img
                                                                        alt="..."
                                                                        className="img-rounded img-responsive"
                                                                        src={IMAGE_URL + order.shop.shopId + '/' + order.shop.shopImage} />
                                                                </Col>
                                                                <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">
                                                                    <h6> {order.shop.shopName}</h6>

                                                                    {order.orderedItems && order.orderedItems.map((orderItem, i) => {
                                                                        const products = Object.keys(orderItem);

                                                                        return (
                                                                            <li className="mb-0">
                                                                                {products.map((product) => {
                                                                                    const addOns = orderItem[product] ? orderItem[product]['addOns'] : [];
                                                                                    return (
                                                                                        <small>
                                                                                            <li>{product} x {orderItem[product]['quantity']}</li>
                                                                                            <li>
                                                                                                <Row className="no-gutters">
                                                                                                    {addOns.map((variation) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <Col xs="9"> {variation.name} : {variation.value} &nbsp; </Col>
                                                                                                                <Col xs="3">  {formatNumber(variation.price)}  </Col>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                    <Col xs="9"> Item Price : </Col>
                                                                                                    <Col xs="3"> <Label color="warning" className="label label-warning"> {formatNumber(orderItem[product]['totalItemPrice'])}</Label></Col>
                                                                                                </Row>
                                                                                                <hr />
                                                                                            </li>
                                                                                        </small>
                                                                                    )
                                                                                })}
                                                                            </li>
                                                                        )
                                                                    })}
                                                                    <Row>
                                                                        <Col className="align-left font-weight-bold" lg="8" md="8" xs="8">Grand Total</Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <Label className="label label-success">{formatNumber(order.totalPayment)}</Label></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><small> Delivery Option </small></Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <small> {order.deliveryOption} </small></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><small> Delivery Fee</small></Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <small> {formatNumber(order.deliveryFee)} </small></Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1"> Delivery Address </h6>
                                                                            <small> {order.customer.address.locationAddress ? order.customer.address.locationAddress + ', ' : ''} </small>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8">#{order.referenceNumber}</Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            {order.rider && order.rider.riderName ?
                                                                <>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col lg="3" md="3" xs="3">
                                                                            <h2 className="float-right mt-0"> <i className="nc-icon nc-delivery-fast align-middle" /></h2>
                                                                        </Col>
                                                                        <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">

                                                                            <Row>
                                                                                <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1">Rider Assigned </h6>
                                                                                    <small> {order.rider.riderName} </small><br />
                                                                                    <small> 0{order.rider.contactNumber} </small>
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                                : ''}
                                                            <hr />
                                                        </li>

                                                    )
                                                })}
                                            </ul>
                                        </>
                                    }
                                    {forPickup.length == 0 ? <></>
                                        :
                                        <>
                                            <img width="8%" alt="Loading" className="img-responsive mr-auto ml-auto mb-0" src="assets\img\loading2.gif"></img>
                                            <h6 className="text-center"> Order is ready. Pending rider pick up..
                                            </h6>
                                            <hr />
                                            <ul className="list-unstyled listing mb-0">

                                                {forPickup && forPickup.map((order) => {
                                                    return (

                                                        <li>
                                                            <Row>
                                                                <Col className="ml-auto mr-auto" lg="3" md="3" xs="3">
                                                                    <img
                                                                        alt="..."
                                                                        className="img-rounded img-responsive"
                                                                        src={IMAGE_URL + order.shop.shopId + '/' + order.shop.shopImage} />
                                                                </Col>
                                                                <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">
                                                                    <h6> {order.shop.shopName}</h6>

                                                                    {order.orderedItems && order.orderedItems.map((orderItem, i) => {
                                                                        const products = Object.keys(orderItem);

                                                                        return (
                                                                            <li className="mb-0">
                                                                                {products.map((product) => {
                                                                                    const addOns = orderItem[product] ? orderItem[product]['addOns'] : [];
                                                                                    return (
                                                                                        <small>
                                                                                            <li>{product} x {orderItem[product]['quantity']}</li>
                                                                                            <li>
                                                                                                <Row className="no-gutters">
                                                                                                    {addOns.map((variation) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <Col xs="9"> {variation.name} : {variation.value} &nbsp; </Col>
                                                                                                                <Col xs="3">  {formatNumber(variation.price)}  </Col>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                    <Col xs="9"> Item Price : </Col>
                                                                                                    <Col xs="3"> <Label color="warning" className="label label-warning"> {formatNumber(orderItem[product]['totalItemPrice'])}</Label></Col>
                                                                                                </Row>
                                                                                                <hr />
                                                                                            </li>
                                                                                        </small>
                                                                                    )
                                                                                })}
                                                                            </li>
                                                                        )
                                                                    })}
                                                                    <Row>
                                                                        <Col className="align-left font-weight-bold" lg="8" md="8" xs="8">Grand Total</Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <Label className="label label-success">{formatNumber(order.totalPayment)}</Label></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><small> Delivery Option </small></Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <small> {order.deliveryOption} </small></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><small> Delivery Fee</small></Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <small> {formatNumber(order.deliveryFee)} </small></Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1"> Delivery Address </h6>
                                                                            <small> {order.customer.address.locationAddress ? order.customer.address.locationAddress + ', ' : ''} </small>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8">#{order.referenceNumber}</Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            {order.rider && order.rider.riderName ?
                                                                <>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col lg="3" md="3" xs="3">
                                                                            <h2 className="float-right mt-0"> <i className="nc-icon nc-delivery-fast align-middle" /></h2>
                                                                        </Col>
                                                                        <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">

                                                                            <Row>
                                                                                <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1">Rider Assigned </h6>
                                                                                    <small> {order.rider.riderName} </small><br />
                                                                                    <small> 0{order.rider.contactNumber} </small>
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                                : ''}
                                                            <hr />
                                                        </li>

                                                    )
                                                })}
                                            </ul>
                                        </>
                                    }
                                    {pendingDelivery.length == 0 ? <></>
                                        :
                                        <>
                                            <img width="8%" alt="Loading" className="img-responsive mr-auto ml-auto mb-0" src="assets\img\loading2.gif"></img>
                                            <h6 className="text-center">&nbsp; Rider is on the way </h6>
                                            <hr />
                                            <ul className="list-unstyled listing mb-0">

                                                {pendingDelivery && pendingDelivery.map((order) => {
                                                    return (

                                                        <li>
                                                            <Row>
                                                                <Col className="ml-auto mr-auto" lg="3" md="3" xs="3">
                                                                    <img
                                                                        alt="..."
                                                                        className="img-rounded img-responsive"
                                                                        src={IMAGE_URL + order.shop.shopId + '/' + order.shop.shopImage} />
                                                                </Col>
                                                                <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">
                                                                    <h6> {order.shop.shopName}</h6>

                                                                    {order.orderedItems && order.orderedItems.map((orderItem, i) => {
                                                                        const products = Object.keys(orderItem);

                                                                        return (
                                                                            <li className="mb-0">
                                                                                {products.map((product) => {
                                                                                    const addOns = orderItem[product] ? orderItem[product]['addOns'] : [];
                                                                                    return (
                                                                                        <small>
                                                                                            <li>{product} x {orderItem[product]['quantity']}</li>
                                                                                            <li>
                                                                                                <Row className="no-gutters">
                                                                                                    {addOns.map((variation) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <Col xs="9"> {variation.name} : {variation.value} &nbsp; </Col>
                                                                                                                <Col xs="3">  {formatNumber(variation.price)}  </Col>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                    <Col xs="9"> Item Price : </Col>
                                                                                                    <Col xs="3"> <Label color="warning" className="label label-warning"> {formatNumber(orderItem[product]['totalItemPrice'])}</Label></Col>
                                                                                                </Row>
                                                                                                <hr />
                                                                                            </li>
                                                                                        </small>
                                                                                    )
                                                                                })}
                                                                            </li>
                                                                        )
                                                                    })}
                                                                    <Row>
                                                                        <Col className="align-left font-weight-bold" lg="8" md="8" xs="8">Grand Total</Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <Label className="label label-success">{formatNumber(order.totalPayment)}</Label></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><small> Delivery Option </small></Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <small> {order.deliveryOption} </small></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><small> Delivery Fee</small></Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <small> {formatNumber(order.deliveryFee)} </small></Col>
                                                                    </Row>
                                                                    <br />
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1"> Delivery Address </h6>
                                                                            <small> {order.customer.address.locationAddress ? order.customer.address.locationAddress + ', ' : ''} </small>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8">#{order.referenceNumber}</Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            {order.rider && order.rider.riderName ?
                                                                <>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col lg="3" md="3" xs="3">
                                                                            <h2 className="float-right mt-0"> <i className="nc-icon nc-delivery-fast align-middle" /></h2>
                                                                        </Col>
                                                                        <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">

                                                                            <Row>
                                                                                <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1">Rider Assigned </h6>
                                                                                    <small> {order.rider.riderName} </small><br />
                                                                                    <small> 0{order.rider.contactNumber} </small>
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                                : ''}
                                                            <hr />
                                                        </li>
                                                    )
                                                })}

                                            </ul>
                                        </>
                                    }

                                    <Row>
                                        <Col xs="6">

                                        </Col>
                                        <Col md="6" lg="6" sm="12" xs="12">
                                            <Button className="btn-round float-right btn-timmy" onClick={() => setTime(new Date())}><i className="nc-icon nc-refresh-69" />&nbsp;refresh status </Button>
                                            <Button className="btn-round float-right btn-success" href="/restaurants">Back</Button>
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                        </Col>
                    </div>

                </div>
            }

        </>
    );
}

export default Waiting;