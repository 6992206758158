import ProductsContext from "contexts/ProductsContext";
import React from "react";
import { IMAGE_URL } from 'constants/index';

class ProfilePageHeader extends React.Component {

  static contextType = ProductsContext;

  constructor(props) {
    super(props);
    this.pageHeader = React.createRef();
  }

  componentDidUpdate() {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        this.pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }

  render() {
    const {shop} = this.context;
    const image = IMAGE_URL + shop.shopId + "/" + shop.restaurantImage;

    return (

      <div
        style={{
          backgroundImage:
            "url(" + (image) + ")",
        }}
        className="page-header page-header-xs"
        data-parallax={true}
        ref={this.pageHeader}
      >
        
        <div className="filter" />
      </div>
    );
  }
}


export default ProfilePageHeader;
