import React, { useContext } from 'react';

import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Label,
  Input

} from "reactstrap";
import ReactStars from "react-rating-stars-component";
import FormGroup from "reactstrap/lib/FormGroup";
import { ProductsContext } from 'contexts/ProductsContext';
import { AuthContext } from "contexts/AuthContext";
import { getReviews, submitReview } from 'helpers/API';
import { convertToDate } from '../../helpers/utils';

const ReviewsTab = ({ list }) => {
  const { shop } = useContext(ProductsContext);
  const { user } = React.useContext(AuthContext);

  const [reviewList, setReviewList] = React.useState([]);
  const [review, setReview] = React.useState('');
  const [rate, setRate] = React.useState('');
  const [disable, setDisabled] = React.useState(true);

  React.useEffect(() => {
    loadReviews();
  }, []);

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  }

  const handleRateChange = (value) => {
    setRate(value);
    setDisabled(value > 0 ? false : true);
  }

  const loadReviews = () => {
    if(!shop){
      return null;
    }
    getReviews(shop.shopId).then(data => {
      setReviewList(data);
    }).catch(err => {
      console.log(err);
    })
  }

  const postReview = () => {
    const body = {
      shopId: shop.shopId,
      review: review,
      customerId: user ? user.userId : 0,
      rating: rate,
      reviewer: user && user.fullName ? user.fullName : 'Anon user'
    }

    submitReview(JSON.stringify(body)).then(data => {
      alert('Thank you for rating!')
      reset();
      loadReviews();
    }).catch(err => {
      console.log(err);
    })
  }

  const reset = () => {
    setReview('');
  }

  return (
    <TabPane tabId="3" id="reviews">
      <Row>
        <Col md="12">
          <FormGroup>
            <Input type="textarea" value={review} onChange={handleReviewChange} placeholder="Write review and rate" rows="2" />
            <Row >
              <Col xs="6">
                <ReactStars
                  count={5}
                  onChange={handleRateChange}
                  size={24}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ffd700"
                  value={Number(rate)}
                />
              </Col>
              <Col xs="6" md="6">
                <Button size="sm" className="float-right" disabled={disable} onClick={() => postReview()}>Submit</Button>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="ml-auto mr-auto" md="12">
          {reviewList.length == 0 ?
            <h4 className="text-muted ml-auto mr-auto mt-0 text-center">Restaurant hasn't received any reviews yet.</h4>
            : ''}
          <ul className="list-unstyled listing">
            {reviewList.map((review) => {
              return (
                <li>
                  <Row>
                    <Col className="ml-auto mr-auto" md="12" >
                      <small> {review.reviewer} </small> &nbsp; {review.rating}/5 Rating
                      <small className="float-right">{convertToDate(review.datePosted)}</small>

                      {review.verifiedPurchase ?
                        <small> &nbsp; <i className="nc-icon nc-diamond text-primary" /> &nbsp;  Verified Customer</small>
                        : ''
                      }
                      <br />
                      <small>
                        {review.review.length > 0 ? review.review : 'No comment from user. Just a rating of ' + review.rating + '/5.'}
                      </small>
                      <br />
                    </Col>
                  </Row>
                  <hr />
                </li>
              )
            })}

          </ul>
        </Col>
      </Row>
    </TabPane>
  )
}

export default ReviewsTab;