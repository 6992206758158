import React from "react";
import { useHistory } from "react-router-dom";
import { login, getCurrentUser } from 'helpers/API';
import { GOOGLE_AUTH_URL, OAUTH2_REDIRECT_URI, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL, ACCESS_TOKEN } from '../constants';

import { Button, Card, Form, Input, InputGroup, Container, Row, Col, FormText } from "reactstrap";
import { AuthContext } from "contexts/AuthContext";
import { IMAGE_URL, ERROR_REQUIRED } from "constants/index"
import AddressPage from "./AddressPage";

const LoginPage = (props) => {
  const { success, isAuthUser, user, error, setLoader, logout } = React.useContext(AuthContext);
  const [userName, setUserName] = React.useState("");
  const [userNameErr, setUserNameErr] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordErr, setPasswordErr] = React.useState("");
  const [credentialsError, setCredentialsError] = React.useState("");
  const [oAuthRedirect, setoAuthRedirect] = React.useState("");
  const history = useHistory();


  const handleUserNameChange = event => {
    setUserName(event.target.value);
    setUserNameErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value);
    setPasswordErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
  }

  const validateCreds = () => {
    let hasErrors = false;

    if (!userName) {
      setUserNameErr(ERROR_REQUIRED)
      hasErrors = true;
    }
    if (!password) {
      setPasswordErr(ERROR_REQUIRED)
      hasErrors = true;
    }
    return hasErrors;
  }

  const handleFormSubmit = event => {
    event.preventDefault();

    if (validateCreds()) {
      return;
    }

    const user_object = {
      email: userName,
      username: userName,
      role: 'USER',
      password: password
    };

    login(user_object)
      .then(response => {
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        handleDashboard();
      }).catch(error => {
        setCredentialsError('Invalid username or password')
      });
  }

  const handleDashboard = () => {
    let redirect = !!props.location.state ? props.location.state.from : '/';
    getCurrentUser().then(response => {
      success(response);

      if (response.address && response.address.locationAddress && response.address.latitude && response.address.longitude) {

        if (redirect === '/updateAddress') {
          history.push('/restaurants');
        } else {
          history.push(redirect);
        }
      } else {
        history.push('/updateaddress');
      }

      // if (!response.address) {
      //   history.push('/update/address');

      // } else {
      //   history.push(redirect);

      // }
    })
  }


  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    let redirect = !!props.location.state ? props.location.state.from : '/';

    if (isAuthUser) {
      history.push(redirect);
    }
    console.log('Redirect: ' + redirect);
    setoAuthRedirect(redirect);
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div className="main ">
        <br />
        <div className="section">
          <Col className="m-auto" sm="12" lg="8" md="8">
            <div className="filter" />
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card card-body mt-3 ml-auto mr-auto bg-light text-dark">
                <img alt="..." className="mx-auto" width="50%" src="assets/img/mycook-3.png" />

                {/* <h6 className="mx-auto text-default">Login via Google/Facebook</h6>
                <div className="social-line text-center">
                  <Button
                    className="btn-neutral btn-just-icon mr-1"
                    size="lg"
                    color="facebook"
                    href={FACEBOOK_AUTH_URL + oAuthRedirect}
                  >
                    <i className="fa fa-facebook-square" />
                  </Button>
                  <Button
                    className="btn-neutral btn-just-icon"
                    size="lg"
                    color="google"
                    href={GOOGLE_AUTH_URL + oAuthRedirect}
                  >
                    <i className="fa fa-google-plus" />
                  </Button>
                </div>
                <small className="mx-auto">or by email address</small> */}

                <p className="mx-auto">Login your account</p>
                {credentialsError ? <FormText color="danger" className="mx-auto">{credentialsError}</FormText> : ''}
                <br />
                <Form >
                  <label>Email Address</label>
                  <Input placeholder="Email Address" name="username" type="text" onChange={handleUserNameChange} />
                  <FormText className="text-danger">  {userNameErr ? userNameErr : ''}</FormText>
                  <label>Password</label>
                  <Input placeholder="Password" name="password" type="password" onChange={handlePasswordChange} />
                  <small className="text-danger">  {passwordErr ? passwordErr : ''}</small>
                  <br />
                  <Button type="submit" onClick={handleFormSubmit} block className="btn-round btn-timmy" color="success">
                    Log In
                  </Button>
                </Form>
                <Button
                  className="btn-link text-muted"
                  href="/recoverpassword"
                >
                  Forgot password?
                </Button>
                <Button
                  className="btn-link text-muted"
                  href="/register"
                >
                  Create Account
                </Button>
              </Card>
            </Col>
          </Col>
        </div>

      </div>
    </>
  );
}

export default LoginPage;
