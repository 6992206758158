import React from "react";

import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardImg,
    Alert, Container, Badge
} from "reactstrap";
import { Link } from "react-router-dom";
import ProductsContext from "contexts/ProductsContext.js";
import { IMAGE_URL } from 'constants/index';
import { displayTime } from 'helpers/utils';
import ReactStars from "react-rating-stars-component";
import { formatNumber } from "helpers/utils";

class Shops extends React.Component {
    static shops = ProductsContext;
    constructor(props) {
        super(props);
    }

    render() {
        const shops = this.props.list;
        const closedShops = shops.filter(item => !item.isReadyToAcceptOrder && item.serviceType === 'Shop');
        const openShops = shops.filter(item => item.isReadyToAcceptOrder && item.serviceType === 'Shop');

        return (
            <>
                {/* </Row> */}
                <Row>
                    {openShops && openShops.map((shop, index) => {
                        const image = IMAGE_URL + shop.shopId + "/" + shop.restaurantImage;

                        return (
                            <Col key={shop.shopId} md="3" sm="6">
                                <Card className="card-plain">
                                    <Link to={{
                                        pathname: '/shop/' + shop.shopId,
                                        state: {
                                            shop: shop
                                        }
                                    }}>
                                        <CardBody >
                                            <Row >
                                                <Col md="12" xs="6">
                                                    <img className="img-responsive " top="true" width="100%" height="50%" src={image} />
                                                </Col>
                                                <Col className="text-left" md="12" xs="6">
                                                    <p className="text-white mb-0">{shop.shopName} &nbsp;
                                                        {shop.activePromoType ?
                                                            <small>
                                                                <Badge color="warning"><i className="nc-icon align-middle nc-tag-content" />
                                                                    {shop.activePromoType == 'Numeric' ? formatNumber(shop.activePromo) + ' off' : shop.activePromo + '% Discount'}
                                                                </Badge></small>
                                                            : ''}
                                                    </p>
                                                    <label className="text-white"> {shop.brgy}  <small> <i className="nc-icon nc-delivery-fast align-middle text-white" />&nbsp;{shop.preparationTime} min</small></label> <br />
                                                    <small className="label label-timmy mr-1"><i className="nc-icon align-middle nc-bell-55" /> &nbsp; {shop.businessHrOpen ? displayTime(shop.businessHrOpen) : '8:00 AM'} - {shop.businessHrClose ? displayTime(shop.businessHrClose) : '5:00 PM'}</small>
                                                    <ReactStars
                                                    disabled={true}
                                                        count={5}
                                                        size={10}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        activeColor="#ffd700"
                                                        value={Number(shop.rating)}
                                                    />
                                                    {/* <Badge color="warning"><i className="nc-icon align-middle nc-tag-content" />
                                                    &nbsp; {shop.activePromoType == 'Numeric' ? formatNumber(shop.activePromo) : shop.activePromo + '% Discount'}
                                                    </Badge> */}
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                        )
                    })
                    }
                </Row>
                {closedShops.length > 0 ?
                    <h4 className="text-white"> Closed shops. Will open soon </h4>
                    : ''}
                <Row>
                    {closedShops && closedShops.map((shop, index) => {
                        const image = IMAGE_URL + shop.shopId + "/" + shop.restaurantImage;
                        return (
                            <Col key={shop.shopId} md="3" sm="6">
                                <Card className="card-plain">
                                    <Link to={{
                                       pathname: '/shop/' + shop.shopId,
                                       state: {
                                           shop: shop
                                       }
                                    }}>
                                        <CardBody >
                                            <Row >
                                                <Col md="12" xs="6">
                                                    <img className="img-responsive closedshop" top="true" width="100%" height="50%" src={image} />
                                                </Col>
                                                <Col className="text-left" md="12" xs="6">
                                                    <p className="text-white">{shop.shopName}</p>
                                                    <label className="text-white"> {shop.brgy}  <small> <i className="nc-icon nc-delivery-fast align-middle text-white" />&nbsp;{shop.preparationTime} min</small></label> <br />
                                                    <small className="label label-timmy mr-1"><i className="nc-icon align-middle nc-bell-55" /> &nbsp; {shop.businessHrOpen ? displayTime(shop.businessHrOpen) : '8:00 AM'} - {shop.businessHrClose ? displayTime(shop.businessHrClose) : '5:00 PM'}</small>
                                                    <ReactStars
                                                        count={5}
                                                        size={10}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        activeColor="#ffd700"
                                                        value={Number(shop.rating)}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                        )
                    })
                    }
                </Row>
            </>
        );
    }
}

export default Shops