import React from "react";

import SearchNavBar from "components/search/SearchNavBar.js";
import Footer from "components/common/Footer.js";
import Information from "components/cart/Information.js";

import { Container, Row } from "reactstrap";

function PaymentsPage() {
  
    return (
        <>
         
            <div className="main">
                        <Row>
                            <Information />
                        </Row>
                </div>

            {/* <Footer /> */}
        </>
    );
}

export default PaymentsPage;
