import React from "react";

import { Button, Row, Col, Container } from "reactstrap";

function FooterArrow() {
    return (
        <footer className="footer-next">

            <Col>
                <Button size="sm" className="btn-timmy float-right m-2">Save<i className="nc-icon nc-minimal-right" /></Button>
                <Button size="sm" className="btn-timmy float-left m-2"><i className="nc-icon nc-minimal-left" /></Button>
            </Col>

        </footer>
    );
}

export default FooterArrow;
