import React, { useState, useContext } from "react";
import { updateAddress } from 'helpers/API';
import { Button, Card, Form, Input, InputGroup, InputGroupAddon, Col, FormText, Modal, ModalBody, FormGroup } from "reactstrap";
import { ERROR_REQUIRED } from "constants/index"
import { useHistory } from "react-router-dom";
import logo from "assets/img/mycook-2.png";
import GoogleMap from "components/search/GoogleMap";
import FooterArrow from "components/common/FooterArrow";
import Label from "reactstrap/lib/Label";
import SearchNavBar from "components/search/SearchNavBar";
import { updateCity } from "helpers/API";
import { getCurrentUser } from "helpers/API";
import { AuthContext } from "contexts/AuthContext";


const UpdateAddress = () => {
    const { success } = React.useContext(AuthContext);

    const [landmark, setLandmark] = React.useState('');

    const [houseNum, setHouseNum] = React.useState('');

    const [street, setStreet] = React.useState('');
    const [streetError, setStreetError] = React.useState('');

    const [brgy, setBrgy] = React.useState('');
    const [brgyError, setBrgyError] = React.useState('');

    const [city, setCity] = React.useState('Bayugan');
    const [cityError, setCityError] = React.useState('');

    const [modal, setModal] = React.useState(false);
    const [modalMsg, setModalMsg] = React.useState('');

    const [hideMap, setHideMap] = React.useState(false);

    const history = useHistory();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleLandmarkChange = event => {
        setLandmark(event.target.value);
    }
    const handleHouseNumChange = event => {
        setHouseNum(event.target.value);
    }
    const handleStreetChange = event => {
        setStreet(event.target.value);
        setStreetError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }
    const handleBrgyChange = event => {
        setBrgy(event.target.value);
        setBrgyError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }
    const handleCityChange = event => {
        setCity(event.target.value);
        setCityError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }

    const toggleModal = (msg) => {
        setModal(!modal);
        setModalMsg(msg);
    };



    const validate = event => {
        let hasErrors = false;

        if (!street) {
            setStreetError(ERROR_REQUIRED)
            hasErrors = true;
        }
        if (!brgy) {
            setBrgyError(ERROR_REQUIRED);
            hasErrors = true;
        }
        if (!city) {
            setCityError(ERROR_REQUIRED);
            hasErrors = true;
        }
        if (city && !city.includes('bayugan') && !city.includes('Bayugan')) {
            toggleModal('We are only available in Bayugan City')
            hasErrors = true;
        }

        return hasErrors;
    }

    const handleFormSubmit = event => {
        event.preventDefault();

        if (validate()) {
            return;
        }
        const address = {
            landmark: landmark,
            houseNum: houseNum,
            street: street,
            brgy: brgy,
            city: city
        };

        updateAddress(address)
            .then(response => {
                alert("Successfully updated address")
                history.push("/list")
            }).catch(error => {
                toggleModal(error.message);
            });
    }

    const submitCity = event => {
        console.log('city: ' +city)
        updateCity(city).then(response => {
            getCurrentUser().then(response => {
                success(response);
    
              })
            setHideMap(false);
        }).catch(error => {
            toggleModal(error.message);
        })
    }


    return (
        <>
            <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggleModal('')}>
                <ModalBody className="text-center">
                    <i className="nc-icon nc-alert-circle-i text-info align-middle" /> &nbsp;
                    {modalMsg}
                </ModalBody>
            </Modal>

            {!hideMap ? <GoogleMap /> :
                <div className="main ps">
                    <br /><br /><br /><br />
                    <div className="section">

                        <Col className="m-auto" sm="12" lg="8" md="8">
                            <Col className="ml-auto mr-auto" lg="4">
                                <Card className="card card-body mt-3 ml-auto mr-auto bg-light text-dark">
                                    <h4 className="mx-auto text-muted">Let us know where you are!</h4>
                                    <FormGroup>
                                        <center><Label for="exampleSelect">Select City</Label></center>
                                        <Input type="select" name="select" id="exampleSelect" onChange={handleCityChange}>
                                            <option>Bayugan</option>
                                            <option>San Fransisco</option>
                                            <option>Siargao</option>
                                        </Input>
                                    </FormGroup>
                                    <Button className="btn-timmy " onClick={()=>submitCity()} >  Save & Continue </Button>

                                </Card>
                            </Col>
                        </Col>
                    </div>

                </div>
            }
        </>
    );
}

export default UpdateAddress;
