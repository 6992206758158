import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import LandingNavBar from "components/common/LandingNavBar.js";
import LandingPageHeader from "components/common/LandingPageHeader.js";
import Footer from "components/common/Footer.js";
import FormText from "reactstrap/lib/FormText";
import SectionCarousel from "components/common/SectionCarousel";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <LandingNavBar />
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">

                <p>To all the Bayuganons, no need to go out for cravings or groceries. Just this few taps: </p>
                <img alt="..." className="img-fluid" src={require("assets/img/use-case-3.PNG")} />
                <h2 className="title">About Timmy</h2>
                <h5>
                  Timmy is a homegrown tech startup in Bayugan City. Our goal is to connect people from local vendors to their daily needs through digital technology. We empower businesses and help them grow while providing the most convenient way of delivery service to the customer, thus build stronger communities.
                  </h5>

              </Col>
            </Row>
          </Container>
        </div>

        <div className="section section-timmy landing-section text-white ">
          <Container>
            <Row>
              <Col md="6">
                <h2 className="text-left">Become one of our merchants
                <small className="text-white"> Let's go digital and increase your sales!</small>
                </h2> <br />
                <Button href="/merchant" className="btn-fill align-center" color="warning" size="lg"> More info </Button>
              </Col>
              <Col className="ml-auto mr-auto" md="6"></Col>

            </Row>
          </Container>
        </div>
        <div className="section landing-section  ">
          <Container>

            <Row>
              <Col className="ml-auto mr-auto" md="6"></Col>

              <Col className="text-right" md="6">
                <h2 className="">Apply as a Rider
                <small > More deliveries, more income</small>
                </h2> <br />
                <Button href="/rider" className="btn-fill" color="warning" size="lg"> Apply here </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="section section-timmy landing-section text-white ">
          <Container>
            <Row>
              <Col md="6">
                <h2 className="text-left">Application Usage
                <small className="text-white"> Terms, Conditions, Privacy Policy</small>
                </h2> <br />
                <Button href="/policy" className="btn-fill align-center" color="warning" size="lg"> Read here </Button>
              </Col>
              <Col className="ml-auto mr-auto" md="6"></Col>

            </Row>
          </Container>
        </div>
      <Footer />
    </>
  );
}

export default LandingPage;
