import React from "react";
import { AuthContext } from "contexts/AuthContext";
import { updateSettings, getCurrentUser, updatePassword } from 'helpers/API';
import { Button, Card, Form, Input, Container, Row, Col, InputGroup, InputGroupAddon, Modal, ModalBody } from "reactstrap";
import {  ERROR_REQUIRED } from "constants/index"
import FormText from "reactstrap/lib/FormText";

const Profile = (props) => {
    const { user, success } = React.useContext(AuthContext);
    const [fullName, setFullName] = React.useState('');
    const [contactNumber, setContactNumber] = React.useState('');
    const [houseNum, setHouseNum] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [brgy, setBrgy] = React.useState('');
    const [city, setCity] = React.useState('');
    const [landmark, setLandmark] = React.useState('');
    const [currentPassword, setCurrentPassword] = React.useState(null);
    const [currentPasswordErr, setCurrentPasswordErr] = React.useState(null);
    const [newPassword, setNewPassword] = React.useState(null);
    const [newPasswordErr, setNewPasswordErr] = React.useState('')
    const [editable, setEditable] = React.useState(false);

    const [contactNumberError, setcontactNumberError] = React.useState('');
    const [fullNameErr, setFullNameErr] = React.useState('');

    const [modal, setModal] = React.useState(false);
    const [modalMsg, setModalMsg] = React.useState('');
    const toggleModal = (msg) => {
        setModal(!modal);
        setModalMsg(msg)
    };

    React.useEffect(() => {
        refreshData();
    }, []);

    const refreshData = () => {
        getCurrentUser().then(response => {
            success(response);
            setFullName(response.fullName ? response.fullName : '')
            setContactNumber(response.contactNumber ? response.contactNumber : '');
            setHouseNum(response.address && response.address.houseNum ? response.address.houseNum : '')
            setStreet(response.address && response.address.street ? response.address.street : '')
            setBrgy(response.address && response.address.brgy ? response.address.brgy : '')
            setCity(response.address && response.address.city ? response.address.city : '')
            setLandmark(response.address && response.address.landmark ? response.address.landmark : '')
        })
    }
    const handleFullNameChange = event => {
        setFullName(event.target.value);
        setFullNameErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)

    }
    const handleContactNumberChange = event => {
        setContactNumber(event.target.value.replace(/[^\d+\.?\d*]/, ''));
        setcontactNumberError(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
        setcontactNumberError(event.target.value.length > 0 && event.target.value.length == 10 ? '' : 'Must be a 10-digit number')
    }
    
    const handleHouseNumChange = event => {
        setHouseNum(event.target.value);
    }
    const handleStreetChange = event => {
        setStreet(event.target.value);
    }
    const handleBrgyChange = event => {
        setBrgy(event.target.value);
    }
    const handleCityChange = event => {
        setCity(event.target.value);
    }
    const handleLandmarkChange = event => {
        setLandmark(event.target.value);
    }
    const handleCurrentPasswordChange = event => {
        setCurrentPassword(event.target.value);
        setCurrentPasswordErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }
    const handleNewPasswordChange = event => {
        setNewPassword(event.target.value);
        setNewPasswordErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }
    const handleFormSubmit = event => {
        event.preventDefault();

        if (contactNumber.length != 10) {
            toggleModal('Invalid Contact Number. Must be a 10-digit number')
            setcontactNumberError('Must be a 10-digit number');
            return;
        }

        if (fullName.length ==0) {
            toggleModal('Invalid name. Must not be empty')
            return;
        }
        const address = {
            houseNum: houseNum,
            street: street,
            brgy: brgy,
            city: city,
            landmark: landmark
        }

        const settings = {
            userId: user.userId,
            fullName: fullName,
            contactNumber: contactNumber,
            address: address
        };

        updateSettings(JSON.stringify(settings))
            .then(response => {
                toggleModal('Successfully updated')
                success(response);
                refreshData()
            }).catch(error => {
                toggleModal(error.message);
            });
    }


    const changePass = () => {

        if (validateCreds()) {
            return;
        }
        const settings = {
            userId: user.userId,
            currentPassword: currentPassword,
            newPassword: newPassword
        };

        updatePassword(JSON.stringify(settings))
            .then(response => {
                toggleModal('Password successfully changed')
                refreshData()
            }).catch(error => {
                toggleModal(error.message);
            });
    }

    const validateCreds = () => {
        let hasErrors = false;

        if (!currentPassword) {
            setCurrentPasswordErr(ERROR_REQUIRED)
            hasErrors = true;
        }
        if (!newPassword) {
            setNewPasswordErr(ERROR_REQUIRED)
            hasErrors = true;
        }
        return hasErrors;
    }
    return (
        <>
            <div className="filter" />
            <Col className="ml-auto mr-auto" lg="8" >
                <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggleModal()}>
                    <ModalBody className="text-center">
                        {/* <small> {modalMsg} </small> */}
                        <i className="nc-icon nc-alert-circle-i text-info align-middle" /> &nbsp;  {modalMsg}
                    </ModalBody>
                </Modal>
                <Card className="card card-body border-0 mt-3 ml-auto mr-auto bg-light text-dark">
                    <h3 className="mx-auto"> <i className="nc-icon nc-alert-circle-i align-middle" /> &nbsp; Account </h3>
                    <label>Full Name</label>
                    <Input type="text" placeholder="Full Name" disabled={editable} value={fullName} onChange={handleFullNameChange} />
                    <FormText className="text-danger">{fullNameErr ? fullNameErr : ''}</FormText>

                    <Row>
                        <Col lg="6">
                            <label>Contact Number</label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">+63</InputGroupAddon>
                                <Input type="number" value={contactNumber} onChange={handleContactNumberChange} />
                            </InputGroup>
                            <FormText className="text-danger">{contactNumberError ? contactNumberError : ''}</FormText>

                        </Col>
                        <Col lg="6">
                            <label>Email Address</label>
                            <Input type="text" disabled value={!!user.emailAddress ? user.emailAddress : ''} />
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col lg="6">
                            <label>House #</label>
                            <Input type="text" value={houseNum} disabled={editable} onChange={handleHouseNumChange} placeholder="House #" /></Col>
                        <Col lg="6">
                            <label>Street</label>
                            <Input type="text" value={street} disabled={editable} onChange={handleStreetChange} placeholder="Street" /></Col>
                        <Col lg="6">
                            <label>Brgy</label>
                            <Input type="text" value={brgy} disabled={editable} onChange={handleBrgyChange} placeholder="Brgy." /></Col>
                        <Col lg="6">
                            <label>City</label>
                            <Input type="text" value={city} disabled={editable} onChange={handleCityChange} placeholder="City/Municipality" /></Col>
                        <Col lg="12">
                            <label>Landmark</label>
                            <Input type="text" value={landmark} disabled={editable} onChange={handleLandmarkChange} placeholder="Landmark" /></Col>

                    </Row> */}

                    <br />
                    <Button className="btn-round btn-timmy" onClick={handleFormSubmit} >
                        Update
                  </Button>
                </Card>
            </Col>
            <Col className="ml-auto mr-auto" lg="8" >
                <Card className="card card-body border-0 mt-3 ml-auto mr-auto bg-light text-dark">
                    <h3 className="mx-auto"> &nbsp; Change Password </h3>

                    <Row>
                        <Col lg="6">
                            <label>Current Password</label>
                            <Input type="password" disabled={editable} onChange={handleCurrentPasswordChange} />
                            <small className="text-danger">  {currentPasswordErr ? currentPasswordErr : ''}</small>
                        </Col>
                        <Col lg="6">
                            <label>New Password</label>
                            <Input type="password" disabled={editable} onChange={handleNewPasswordChange} />
                            <small className="text-danger">  {newPasswordErr ? newPasswordErr : ''}</small>
                        </Col>
                    </Row>
                    <br />
                    <Button className="btn-round btn-timmy" onClick={changePass} >
                        Save
                  </Button>
                </Card>

            </Col>
            {/* <Col className="ml-auto mr-auto" lg="8" >
                <Card className="card card-body border-0 mt-3 ml-auto mr-auto bg-light text-dark">
                    <h3 className="mx-auto"> <i className="nc-icon nc-credit-card align-middle " /> &nbsp; Payments </h3>
                    <label>Payment 1</label>
                    <Row>
                        <Col lg="6"><Input type="text" placeholder="13-Digit Credit Card Number" /></Col>
                        <Col lg="3"><Input type="text" placeholder="01/01" /></Col>
                        <Col lg="3"><Input type="text" placeholder="000" value={!!user.ccLast4digit ? user.ccLast4digit : ''} /></Col>
                    </Row>
                    <label>Payment 2</label>
                    <Row>
                        <Col lg="6"><Input type="text" placeholder="13-Digit Credit Card Number" /></Col>
                        <Col lg="3"><Input type="text" placeholder="Expiry Date" /></Col>
                        <Col lg="3"><Input type="text" placeholder="CC" value={!!user.ccLast4digit ? user.ccLast4digit : ''} /></Col>
                    </Row>
                    <br />
                    <Button className="btn-round" color="outline-success">
                        Save
                  </Button>
                </Card>

            </Col> */}
        </>
    );
}

export default Profile;
