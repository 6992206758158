import React from "react";

import LandingNavBar from "components/common/LandingNavBar.js";
import Footer from "components/common/Footer.js";
import LandingPageHeader from "components/common/LandingPageHeader";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody, CardHeader
} from "reactstrap";
import NavBar from "components/common/NavBar";
import SearchNavBar from "components/search/SearchNavBar";
function AboutPage() {
  document.documentElement.classList.remove("nav-open");
  let pageHeader = React.createRef();

  return (
    <>
      <SearchNavBar />
     
      <div className="main">
        <div className="section text-center">
          <Container>
          <Card>
                <CardHeader>
                  <h3 className="text-muted text-center">Terms and Conditions</h3>
                </CardHeader>
                <CardBody>
                  <h6 className="text-muted">1. About the App</h6>
                  <p>
                    1.1 Welcome to TimmyExpress (the Application). The Application that provides delivery services (the Services) of goods (the Orders) from select establishments (the Vendors) to the customer (the User). The Services are handled by participating drivers (the Rider).
                  </p>
                  <p>
                    1.2 The Application is operated by TimmyExpress. The terms “we”, “us”, and “our” in this app refer to TimmyExpress.
                  </p>
                  <p>
                    1.3 Please read these terms and conditions (the Terms) carefully. Your access to and use of the Application is conditioned on your acceptance of and compliance with these terms, which apply to all users and others who access both the Application and web support. If you do not agree with the Terms, you must cease usage of the Application, or any of its services, immediately.
                  </p>
                  <p>
                    1.4 TimmyExpress has the right to review and modify any of the Terms by updating the Application at its sole discretion. It is further necessary that we will notify you of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication.
                  </p>

                  <h6 className="text-muted">2. Use of the Application</h6>
                  <p>
                    2.1 The User is permitted to install a copy of the Application on User’s mobile device for use in the Philippines provided that User shall not use the Application for any commercial purposes.
                  </p>
                  <p>
                    2.2 TimmyExpress shall not be liable for Orders that encounter delivery issues due to incomplete, incorrect or missing information provided by you. You are obliged to provide information that is complete, accurate and truthful for the proper processing of the Order, including your delivery address and contact information.
                  </p>
                  <p>
                    2.3 If you wish to delete the Application, please send us an email through www.timmyexpress.com.
                  </p>

                  <h6 className="text-muted">3. Restrictions</h6>
                  <p>
                    3.1 The User shall not utilize the Application for or to promote any illegal acts.
                  </p>
                  <p>
                    3.2 The User shall not use the Application to produce any email advertisements or spam emails.
                  </p>
                  <p>
                    3.3 The User shall not use the Application in any way to track, harass or bully any person.
                  </p>
                  <p>
                    3.4. The User shall not in any way interrupt/destroy the operation of the Application or the servers/network linked with the Application, or to violate the network requirements, process, or the herein terms and conditions.
                  </p>
                  <p>
                    3.5 The User shall not use the Application in another person’s name or use the Application with property of others without consent of its owner.
                  </p>
                  <p>
                    3.6 The User shall not use the Application for personal gains, in any of its kind.
                  </p>

                  <h6 className="text-muted">4. Fees and Charges</h6>
                  <p>
                    4.1 Prices. You understand that: (a) the prices for menu items displayed through the Application may differ from the prices offered or published by outside Vendors for the same items and/or from prices available at other third-party websites and that such prices may not be the lowest prices at which the items are sold; (b) TimmyExpress has no obligation to itemize its costs, profits or margins when publishing such prices; and (c) we reserve the right to change such prices at any time, at its discretion. Payment will be processed by the Application, using the preferred payment method designated in the User’s account.
                  </p>
                  <p>
                    4.2 No Refunds. Charges paid by you for completed and delivered orders are final and non-refundable. The Application has no obligation to provide refunds or credits, but may grant them, in each case in TimmyExpress’s sole discretion.
                  </p>
                  <p>
                    4.3 Refusals. In the event that the Order is not accepted or refused by the recipient or the recipient’s representative, all expenses for the return of the Order to the User shall be shouldered by the User.
                  </p>
                  <p>
                    4.4 Cancellation. No cancellation fees are chargeable as long as an Order is cancelled and communicated within fifteen (15) minutes before the start of the Order pickup time. In case the cancellation was made after the allotted time, cancellation fee is based on the delivery fee.
                  </p>
                  <p>
                    4.5 Mode of Payments. The User has the option to choose whether to pay directly through the Application using our payment channel partners or cash upon delivery.
                  </p>
                  <p>

                    4.6 Fees for Services and Software. The Application may change the fees for our Services as we deem necessary or appropriate for our business.
                  </p>

                  <h6 className="text-muted">5. Personal Data and Privacy</h6>
                  <p>
                    5.1 The User may be required to submit personal data to TimmyExpress in order to use certain functions of the Application, and the User confirms that the personal data so provided is true, correct, and up to date.
</p>
                  <p>
                    5.2 The User agrees that TimmyExpress shall not be liable for any misuse by the Rider of the User’s personal data.
</p>
                  <p>
                    5.3 The User will receive and use the Rider’s personal data (Rider’s submitted name and contact number) for the purpose of fulfillment of the User’s Order pursuant to the obligations of the User and Rider under their relevant Contract.
</p>
                  <p>
                    5.4 The User shall not use the Rider’s personal data for any illegal acts. The User agrees that he will be liable to the Rider for any misuse of the Rider’s personal data, and he agrees to hold TimmyExpress free and harmless from the misuse of the Rider’s personal data.
</p>

                  <h6 className="text-muted">6. Disclaimer of Warranties</h6>
                  <p>
                    6.1 You expressly understand and agree that to the fullest extent of law, your use of the website, application, and services is entirely at your own risk. Changes are periodically made to the website, application, and services and may be made at any time without notice to you. The website, application, and services are provided on an “as is” basis without warranties of any kind, either express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose and non-infringement. The company makes no warranties or representations about the accuracy, reliability, completeness or timeliness of the content made available through the website, application, or services, or the services, application, text, graphics or links.
</p>
                  <p>
                    6.2 The company does not warrant that the website, application, or services will operate error-free or that the website, application, or services are free of computer viruses and other harmful malware. If your use of the website, application or services results in the need for servicing or replacing equipment or data, the company shall not be responsible for those economic costs.
</p>


                  <h6 className="text-muted">7. Internet Delays</h6>
                  <p>
                    The Application may be subject to limitations, delays, and other problems inherent in the use of the Internet and electronic communications. TimmyExpress is not responsible for any delays, delivery failures, or other economic damage resulting from such problems.
                    </p>


                  <h6 className="text-muted"> 8. Disclaimer</h6>
                  <p>The User hereby expressly agrees and acknowledges that:
                  TimmyExpress is a mobile device application, and does not represent itself to be, engaged in the activities of a common or private carrier or a public utility.
                  </p>
                  <h6 className="text-muted"> 9. How to connect us</h6>
                  <p>
                  For customer support, you may reach out to us through our Facebook page, Timmy Express.


                  </p>
                  
                </CardBody>
              </Card>
           
          </Container>
        </div>


      </div>

      <Footer />
    </>
  );
}

export default AboutPage;
