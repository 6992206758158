import React, { useContext } from 'react';
import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from '../../components/icons'
import { CartContext } from '../../contexts/CartContext';
import { Col, Row, Label, Button } from "reactstrap";
import { formatNumber, isEqualVariant } from '../../helpers/utils';
import { IMAGE_URL } from 'constants/index';

const CartItem = ({ product }) => {

    const { increase, decrease, removeProduct, cartItems } = useContext(CartContext);

    const decreaseProd = product => {
        const productToRemove = findProductInCart(product);
        if (productToRemove.quantity > 1) {
            decrease(productToRemove)
        } else {
            removeProduct(productToRemove);
        }
    }

    const findProductInCart = product => {
        return cartItems.find(item => (item.productId == product.productId && isEqualVariant(item, product)))
    }

    const displayAddOns = product => {
        let addOns = [];
        for (let i = 1; i <= 5; i++) {
            let addOn = product["addOn" + i];
            if (addOn.variation) {
                addOns.push(<Label className="label label-default"><small>{addOn.variation.variationName} : {addOn.option.optionName} </small></Label>)
            }
        }
        if (product.specialRequest) {
            addOns.push(<Label className="label label-default"><small>Notes : {product.specialRequest} </small></Label>)
        }
        return addOns;
    }
    return (
        <ul className="list-unstyled listing">

            <li>
                <Row>
                    {product.image1 ? 
                    <>
                    <Col className="ml-auto mr-auto" lg="2" md="4" xs="6">
                        <img
                            alt="..."
                            className="img-fluid img-responsive"
                            src={IMAGE_URL + product.shopId + '/' +product.image1}
                        />
                    </Col>
                    <Col className="ml-auto mr-auto" lg="8" md="8" xs="6">
                        <h5 className="mb-0">{product.name}</h5>
                        {displayAddOns(product)}
                        <br />
                        <Label className="label label-warning float-right">Price: {formatNumber((product.price + product.addOnsPrice) * product.quantity)} </Label>
                        <small >Qty: {product.quantity} &nbsp;</small>
                        <button
                            onClick={() => increase(product)}
                            className="btn btn-primary btn-sm mr-2 mb-1">
                            <PlusCircleIcon width={"20px"} />
                        </button>
                        <button
                            onClick={() => decreaseProd(product)}
                            className="btn btn-danger btn-sm mb-1">
                            <MinusCircleIcon width={"20px"} />
                        </button>
                    </Col>
                    </>
                    :
                    <>
                    <Col className="ml-auto mr-auto" lg="2" md="4" xs="6">
                    <h5 className="mb-0">{product.name}</h5>
                    </Col>
                    <Col className="ml-auto mr-auto" lg="8" md="8" xs="6">
                        {displayAddOns(product)}
                        <br />
                        <Label className="label label-warning float-right">Price: {formatNumber((product.price + product.addOnsPrice) * product.quantity)} </Label>
                        <small >Qty: {product.quantity} &nbsp;</small>
                        <Button size="sm"
                            onClick={() => increase(product)}
                            className="btn btn-primary btn-sm mr-2 mb-1">
                            <PlusCircleIcon width={"20px"} />
                        </Button>
                        <Button size="sm"
                            onClick={() => decreaseProd(product)}
                            className="btn btn-danger btn-sm mb-1">
                            <MinusCircleIcon width={"20px"} />
                        </Button>
                    </Col>
                    </>
                    }
                </Row>
                <hr />
            </li>
        </ul>
    );
}

export default CartItem;