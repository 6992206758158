import React from "react";
import ProfilePageHeader from "components/common/ProfilePageHeader.js";
import ProfilePageSection from "components/shop/ProfilePageSection.js";
import NavBar from "components/common/NavBar.js";
import Footer from "components/common/Footer.js";
import ProductsContext from "contexts/ProductsContext";
import { useHistory, useParams } from "react-router-dom";
import ShopNavBar from "components/shop/ShopNavBar";

class ShopPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cart: {
        numberOfItems: 0,
        totalPrice: 0
      },
      slots: {},
      reviews: {},
      slotAvailable: 0
    }
  }

  render() {
    
    const shop = this.props.location.state.shop;
    const slots = this.state.slots;
    const reviews = this.state.reviews;
    const slotAvailable = this.state.slotAvailable;
    const contextValues = { shop, slots, reviews, slotAvailable, ...this.state }

    return (
      <>
        <ShopNavBar />
        <ProductsContext.Provider value={contextValues}>
          <ProfilePageHeader />
          <ProfilePageSection />
        </ProductsContext.Provider>
        <Footer />
      </>
    );
  }
}
export default ShopPage;