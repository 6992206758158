import React from "react";
import { AuthContext } from "contexts/AuthContext";
import { formatNumber } from '../../helpers/utils';

import { Button, Card, Label, Form, Input, Container, Row, Col, InputGroup, InputGroupAddon } from "reactstrap";
import { login, getUserOrders } from 'helpers/API';
import FormText from "reactstrap/lib/FormText";
import { IMAGE_URL } from 'constants/index';
import SearchNavBar from "components/search/SearchNavBar.js";
import Footer from "components/common/Footer.js";

const Orders = (disabled) => {

    const { user } = React.useContext(AuthContext);
    const [activeOrders, setActiveOrders] = React.useState([]);
    const [currentlyPreparing, setCurrentlyPreparing] = React.useState([]);
    const [ready, setReady] = React.useState([]);
    const [pendingDelivery, setPendingDelivery] = React.useState([]);
    const [completedOrders, setCompletedOrders] = React.useState([]);
    const [cancelledOrders, setCancelledOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [time, setTime] = React.useState('');

    React.useEffect(() => {
        getUserOrders().then(res => {
            if (res) {
                const activeOrders = res.filter(order => (order.status === 'New'));
                setActiveOrders(activeOrders);

                const preparing = res.filter(order => (order.status === 'Accepted'));
                setCurrentlyPreparing(preparing);

                const readyO = res.filter(order => (order.status === 'Delivery'));
                setReady(readyO);

                const pendingDeliveries = res.filter(order => (order.status === 'InTransit'));
                setPendingDelivery(pendingDeliveries);

                const completedOrders = res.filter(order => (order.status === 'Completed' || order.status === 'Rejected'));
                setCompletedOrders(completedOrders);

                // const cancelledOrders = res.filter(order => (order.status === 'Rejected'));
                // setCancelledOrders(cancelledOrders);

                setLoading(false);

            }
        },
            error => {
                alert(error.message);
            });
    }, [time]);


    const refreshStatus = () => {
        setTime(new Date());
        alert('Order status is refreshed')
    }
    return (
        <>
            {loading ?
                <div className="section section-white text-center">
                    <Col lg="1" md="1">
                        <img width="35%" alt="Loading" className="img-fluid img-responsive timmy-centered" src="assets\img\loading.gif" />
                    </Col>
                </div>
                : <>
                    <SearchNavBar />
                    <div className="section ">
                        <Container>
                            {activeOrders.length == 0 ? <></>
                                :
                                <Col lg="6"  >
                                    <Card className="card card-body border-0 mt-3 ml-auto mr-auto bg-light text-dark">
                                        <>

                                            <h6 className="mx-auto mb-1">
                                                Waiting confirmation from merchant &nbsp;
                                                <img width="8%" alt="Loading" className="img-responsive mr-auto ml-auto mb-0" src="assets\img\loading2.gif"></img>
                                            </h6>

                                            <hr />

                                            <ul className="list-unstyled listing mb-0">
                                                {activeOrders && activeOrders.map((order) => {
                                                    return (
                                                        <li>
                                                            <Row>
                                                                <Col className="ml-auto mr-auto" lg="3" md="3" xs="3">
                                                                    <img
                                                                        alt="..."
                                                                        className="img-rounded img-responsive"
                                                                        src={IMAGE_URL + order.shop.shopId + '/' + order.shop.shopImage} />
                                                                </Col>
                                                                <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">
                                                                    <h6> {order.shop.shopName}</h6>

                                                                    {order.orderedItems && order.orderedItems.map((orderItem, i) => {
                                                                        const products = Object.keys(orderItem);

                                                                        return (
                                                                            <li className="mb-0">
                                                                                {products.map((product) => {
                                                                                    const addOns = orderItem[product] ? orderItem[product]['addOns'] : [];
                                                                                    return (
                                                                                        <small>
                                                                                            <li>{product} x {orderItem[product]['quantity']}</li>
                                                                                            <li>
                                                                                                <Row className="no-gutters">
                                                                                                    {addOns.map((variation) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <Col xs="9"> {variation.name} : {variation.value} &nbsp; </Col>
                                                                                                                <Col xs="3">  {formatNumber(variation.price)}  </Col>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                                    <Col xs="9"> Item Price : </Col>
                                                                                                    <Col xs="3"> <Label color="warning" className="label label-warning"> {formatNumber(orderItem[product]['totalItemPrice'])}</Label></Col>
                                                                                                </Row>
                                                                                                <hr />
                                                                                            </li>
                                                                                        </small>
                                                                                    )
                                                                                })}
                                                                            </li>
                                                                        )
                                                                    })}
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><small> Delivery Option </small></Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <small> {order.deliveryOption} </small></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left" lg="8" md="8" xs="8"><small> Delivery Fee</small></Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <small> {formatNumber(order.deliveryFee)} </small></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="align-left font-weight-bold" lg="8" md="8" xs="8">Grand Total</Col>
                                                                        <Col className="align-left" lg="3" md="4" xs="4"> <Label className="label label-success">{formatNumber(order.totalPayment)}</Label></Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            {order.rider && order.rider.riderName ?
                                                                <>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col lg="3" md="3" xs="3">
                                                                            <h2 className="float-right mt-0"> <i className="nc-icon nc-delivery-fast align-middle" /></h2>
                                                                        </Col>
                                                                        <Col className="ml-auto mr-auto p-0" lg="9" md="9" xs="9">

                                                                            <Row>
                                                                                <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1">Rider Assigned </h6>
                                                                                    <small> {order.rider.riderName} </small><br />
                                                                                    <small> 0{order.rider.contactNumber} </small>
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                                : ''}
                                                            <hr />

                                                        </li>

                                                    )
                                                })}
                                                <Button className="btn-round float-right btn-timmy btn-sm" onClick={refreshStatus}><i className="nc-icon nc-refresh-69" />&nbsp;Get latest status </Button>
                                            </ul>
                                        </>
                                    </Card>
                                </Col>}

                            {currentlyPreparing.length == 0 ? <></>
                                :
                                <Col lg="6"  >
                                    <Card className="card card-body border-0 mt-3 ml-auto mr-auto bg-light text-dark">
                                    <img alt="..." className="mx-auto" width="50%" src="assets/img/loading-3.gif" />

                                        <h6 className="text-center mb-0"> &nbsp; Your order has been acknowledged by merchant. Preparing..</h6>

                                        <hr />

                                        <ul className="list-unstyled listing mb-0">

                                            {currentlyPreparing && currentlyPreparing.map((order) => {
                                                return (

                                                    <li>
                                                        <Row>
                                                            <Col className="ml-auto mr-auto" lg="2" md="2" xs="2">
                                                                <img
                                                                    alt="..."
                                                                    className="img-rounded img-responsive"
                                                                    src={IMAGE_URL + order.shop.shopId + '/' + order.shop.shopImage} />
                                                            </Col>
                                                            <Col className="ml-auto mr-auto p-0" lg="8" md="8" xs="8">
                                                                <h6> {order.shop.shopName}</h6>

                                                                {order.orderedItems && order.orderedItems.map((orderItem, i) => {
                                                                    const products = Object.keys(orderItem); i++;
                                                                    return (
                                                                        <li className="mb-0">
                                                                            {products.map((product) => {
                                                                                return (
                                                                                    <small>
                                                                                        <li>{product} x {orderItem[product]['quantity']}</li>

                                                                                    </small>
                                                                                )
                                                                            })}
                                                                        </li>
                                                                    )
                                                                })}
                                                                <small> To Pay: <Label className="label label-warning">{formatNumber(order.totalPayment)}</Label> </small>
                                                            </Col>
                                                            {/* <Col className="align-left" lg="2" md="2" xs="2"> <small> {order.remainingTime} min </small></Col> */}
                                                        </Row>
                                                        {order.rider && order.rider.riderName ?
                                                            <>
                                                                <hr />
                                                                <Row>
                                                                    <Col lg="4" md="4" xs="4">
                                                                        <h2 className="float-right mt-0"> <i className="nc-icon nc-delivery-fast align-middle" /></h2>
                                                                    </Col>
                                                                    <Col className="ml-auto mr-auto p-0" lg="8" md="8" xs="8">

                                                                        <Row>
                                                                            <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1">Rider Assigned </h6>
                                                                                <small> {order.rider.riderName} </small><br />
                                                                                <small> 0{order.rider.contactNumber} </small>
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            : ''}
                                                        <hr />

                                                    </li>

                                                )
                                            })}
                                            <Button className="btn-round float-right btn-timmy btn-sm" onClick={refreshStatus}><i className="nc-icon nc-refresh-69" />&nbsp;Get latest status </Button>
                                        </ul>

                                    </Card>
                                </Col>
                            }

                            {ready.length == 0 ? <></>
                                :
                                <Col lg="6"  >
                                    <Card className="card card-body border-0 mt-3 ml-auto mr-auto bg-light text-dark">

                                        <h6 className="text-center mb-0"> &nbsp; Order is ready. Pending rider pick up
                                            <small> <img width="8%" alt="Loading" className="img-responsive mr-auto ml-auto mb-0" src="assets\img\loading2.gif" /></small></h6>

                                        <hr />

                                        <ul className="list-unstyled listing mb-0">

                                            {ready && ready.map((order) => {
                                                return (

                                                    <li>
                                                        <Row>
                                                            <Col className="ml-auto mr-auto" lg="2" md="2" xs="2">
                                                                <img
                                                                    alt="..."
                                                                    className="img-rounded img-responsive"
                                                                    src={IMAGE_URL + order.shop.shopId + '/' + order.shop.shopImage} />
                                                            </Col>
                                                            <Col className="ml-auto mr-auto p-0" lg="8" md="8" xs="8">
                                                                <h6> {order.shop.shopName}</h6>

                                                                {order.orderedItems && order.orderedItems.map((orderItem, i) => {
                                                                    const products = Object.keys(orderItem); i++;
                                                                    return (
                                                                        <li className="mb-0">
                                                                            {products.map((product) => {
                                                                                return (
                                                                                    <small>
                                                                                        <li>{product} x {orderItem[product]['quantity']}</li>

                                                                                    </small>
                                                                                )
                                                                            })}
                                                                        </li>
                                                                    )
                                                                })}
                                                                <small> To Pay: <Label className="label label-warning">{formatNumber(order.totalPayment)}</Label> </small>
                                                            </Col>
                                                        </Row>
                                                        {order.rider && order.rider.riderName ?
                                                                <>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col lg="3" md="3" xs="3">
                                                                            <h2 className="float-right mt-0"> <i className="nc-icon nc-delivery-fast align-middle" /></h2>
                                                                        </Col>
                                                                        <Col className="ml-auto mr-auto p-0" lg="8" md="8" xs="8">

                                                                            <Row>
                                                                                <Col className="align-left" lg="8" md="8" xs="8"><h6 className="mb-1">Rider Assigned </h6>
                                                                                    <small> {order.rider.riderName} </small><br />
                                                                                    <small> 0{order.rider.contactNumber} </small>
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                                : ''}
                                                        <hr />

                                                    </li>

                                                )
                                            })}
                                            <Button className="btn-round float-right btn-timmy btn-sm" onClick={refreshStatus}><i className="nc-icon nc-refresh-69" />&nbsp;Get latest status </Button>
                                        </ul>

                                    </Card>
                                </Col>
                            }
                            {pendingDelivery.length == 0 ? <></>
                                :
                                <Col md="6">
                                    <Card className="card card-body border-0 mt-3 ml-auto mr-auto">
                                    <img width="10%" alt="Loading" className="img-responsive mr-auto ml-auto mb-0" src="assets\img\loading2.gif" />
                                        <h6 className="text-center">&nbsp; Rider is on the way</h6>
                                        
                                        <hr />
                                        <ul className="list-unstyled listing mb-0">

                                            {pendingDelivery && pendingDelivery.map((order) => {
                                                return (

                                                    <li>
                                                        <Row>
                                                            <Col className="ml-auto mr-auto" lg="2" md="2" xs="2">
                                                                <img
                                                                    alt="..."
                                                                    className="img-rounded img-responsive"
                                                                    src={IMAGE_URL + order.shop.shopId + '/' + order.shop.shopImage} />
                                                            </Col>
                                                            <Col className="ml-auto mr-auto p-0" lg="8" md="8" xs="7">
                                                                <h6> {order.shop.shopName}</h6>

                                                                {order.orderedItems && order.orderedItems.map((orderItem, i) => {
                                                                    const products = Object.keys(orderItem); i++;
                                                                    return (
                                                                        <li className="mb-0">
                                                                            {products.map((product) => {
                                                                                return (
                                                                                    <small>
                                                                                        <li>{product} x {orderItem[product]['quantity']}</li>

                                                                                    </small>
                                                                                )
                                                                            })}
                                                                        </li>
                                                                    )
                                                                })}
                                                                <small> To Pay: <Label className="label label-warning">{formatNumber(order.totalPayment)}</Label> </small>

                                                            </Col>
                                                        </Row>
                                                        {order.rider && order.rider.riderName ?
                                                            <>
                                                                <hr />
                                                                <Row>
                                                                    <Col lg="3" md="3" xs="4" sm="4">
                                                                        <h2 className="float-right mt-0"> <i className="nc-icon nc-delivery-fast align-middle" /></h2>
                                                                    </Col>
                                                                    <Col className="ml-auto mr-auto p-0" lg="8" md="8" xs="8">

                                                                        <Row>
                                                                            <Col className="align-left" lg="8" md="8" xs="8">
                                                                                <h6 className="mb-1">Rider Assigned </h6>
                                                                                <small> {order.rider.riderName} </small><br />
                                                                                <small> 0{order.rider.contactNumber} </small>
                                                                            </Col>
                                                                        </Row>

                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            : ''}
                                                        <hr />
                                                    </li>

                                                )
                                            })}
                                        </ul>
                                    </Card>
                                </Col>
                            }
                            <Col md="6">
                                <Card className="card card-body border-0 mt-3 ml-auto mr-auto">
                                    <h3 className="mx-auto"><i className="nc-icon nc-check-2 align-middle" /> &nbsp;  Order History </h3>
                                    <hr />
                                    <ul className="list-unstyled listing">

                                        {completedOrders && completedOrders.map((order) => {
                                            return (
                                                <li>
                                                    <Row>
                                                        <Col className="ml-auto mr-auto align-left" lg="3" md="3" xs="3">
                                                            {order.status == 'Completed' ?
                                                                <Label className="label label-success">{order.status}</Label>
                                                                :
                                                                <Label className="label label-danger">{order.status}</Label>
                                                            }
                                                            <br />
                                                            <Label className="label label-warning">{formatNumber(order.totalPayment)}</Label>
                                                        </Col>
                                                        <Col className="ml-auto mr-auto" lg="9" md="9" xs="9">
                                                            <h6> {order.shop.shopName}</h6>
                                                            {order.orderedItems && order.orderedItems.map((orderItem, i) => {

                                                                const products = Object.keys(orderItem);

                                                                return (
                                                                    <li>
                                                                        {products.map((product) => {
                                                                            const addOns = orderItem[product] ? orderItem[product]['addOns'] : [];
                                                                            return (
                                                                                <small> <li>{product} x {orderItem[product]['quantity']}</li> </small>
                                                                            )
                                                                        })}
                                                                    </li>
                                                                )
                                                            })}
                                                            <FormText className="text-danger"><b>{order.status} on {order.scheduledDate}</b></FormText>
                                                            <br />
                                                        </Col>

                                                    </Row>
                                                    <hr />
                                                </li>
                                            )
                                        })}
                                        <></>
                                        {completedOrders.length == 0 ?
                                            <FormText className="text-center">
                                                No completed orders yet.
                                            </FormText>
                                            :
                                            <></>
                                        }
                                        <></>
                                    </ul>
                                </Card>
                            </Col>
                        </Container>
                    </div>
                    <Footer />

                </>
            }
        </>
    );
}

export default Orders;
