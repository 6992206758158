import { API_BASE_URL, ACCESS_TOKEN } from '../constants';

const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

const requestNoContentType = (options) => {
    const headers = new Headers({
    })

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
        );
};

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function updateAddress(body) {
    return request({
        url: API_BASE_URL + "/user/update/address",
        method: 'POST',
        body: JSON.stringify(body)
    });
}

export function forgotPassword(email) {
    return request({
        url: API_BASE_URL + "/user/forgotpassword/"+email,
        method: 'GET'
    });
}
export function validateResetToken(token) {
    return request({
        url: API_BASE_URL + "/user/reset/"+token,
        method: 'GET'
    });
}
export function resetPassword(body) {
    return request({
        url: API_BASE_URL + "/user/changepassword/",
        method: 'POST',
        body: body
    });
}
export function searchAll(){
    return request({
        url: API_BASE_URL + "/api/shops/all",
        method: 'GET'
    });
}

export function getShop(id){
    return request({
        url: API_BASE_URL + "/api/shops/get/"+id,
        method: 'GET'
    });
}

export function getUserOrders() {
    return request({
        url: API_BASE_URL + "/order/get",
        method: 'GET'
    });
}

export function getShopById(shopId){
    return request({
        url: API_BASE_URL + "/api/shops/get/" + shopId,
        method: 'GET'
    });
}
export function getShopsByServiceType(serviceType, city){
    return request({
        url: API_BASE_URL + "/api/shops/get/service/"+city,
        method: 'POST',
        body: JSON.stringify(serviceType)
    });
}

export function getMerchants(serviceType, city){
    const reqBody = {
        "city": city,
        "serviceType": serviceType
    }
    return request({
        url: API_BASE_URL + "/api/shops/",
        method: 'POST',
        body: JSON.stringify(reqBody)
    });
}

export function getSlots(shopId){
    return request({
        url: API_BASE_URL + "/reservation/slot/" + shopId,
        method: 'GET'
    });
}

export function getVoucher(shopId, voucher) {
    return request({
        url: API_BASE_URL + '/cart/voucher/' + shopId + '/' + voucher,
        method: 'GET'
    });
}

export function getDiscount(shopId, totalAmt) {
    return request({
        url: API_BASE_URL + '/cart/discount/' + shopId+ '/'+totalAmt,
        method: 'GET'
    });
}

export function getProductsByShopId(shopId) {
    return request({
        url: API_BASE_URL + '/api/shops/products/' + shopId,
        method: 'GET'
    });
}

export function pay(body) {
    return request({
        url: API_BASE_URL + '/order/new',
        method: 'POST',
        body: body
    });
}

export function updateSettings(body) {
    return request({
        url: API_BASE_URL + '/user/update',
        method: 'POST',
        body: body
    });
}

export function updateNotifToken(token) {
    return request({
        url: API_BASE_URL + '/user/notifToken/' + token ,
        method: 'GET',
    });
}

export function updateName(body) {
    return request({
        url: API_BASE_URL + '/user/update/fullName',
        method: 'POST',
        body: body
    });
}
export function updateCity(body) {
    return request({
        url: API_BASE_URL + '/user/update/city/'  ,
        method: 'POST',
        body: body
    });
}

export function updatePassword(body) {
    return request({
        url: API_BASE_URL + '/user/update/password',
        method: 'POST',
        body: body
    });
}

export function submitReview(body) {
    return request({
        url: API_BASE_URL + '/api/shops/review/submit',
        method: 'POST',
        body: body
    });
}

export function getReviews(shopId) {
    return request({
        url: API_BASE_URL + '/api/shops/reviews/' + shopId,
        method: 'GET'
    });
}

export function sendMerchantApplication(body) {
    return request({
        url: API_BASE_URL + '/apply/merchant',
        method: 'POST',
        body: body

    });
}

export function sendRiderApplication(body) {
    return request({
        url: API_BASE_URL + '/apply/rider',
        method: 'POST',
        body: body

    });
}

