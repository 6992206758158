import React, { createContext, useReducer } from 'react';
import { AuthReducer,isAuthUser } from './AuthReducer';

export const AuthContext = createContext()

const storage = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
const initialState = { user:storage, ...isAuthUser(storage), isLoading: false};

const AuthContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(AuthReducer, initialState)

    const success = payload => {
        dispatch({type: 'success', payload})
    }

    const error = payload => {
        dispatch({type: 'API_ERROR', payload})
    }

    const setLoader = payload => {
        dispatch({type: 'SET_LOADER', payload})
    }

    const logout = payload => {
        dispatch({type: 'LOGOUT', payload})
    }

    const contextValues = {
        success,
        error,
        setLoader,
        logout,
        ...state
    } 

    return ( 
        <AuthContext.Provider value={contextValues} >
            { children }
        </AuthContext.Provider>
     );
}
 
export default AuthContextProvider;
