import React, { useState, useContext } from "react";
import { signup } from 'helpers/API';
import { FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL } from '../constants';
import { Button, Card, Form, Input, InputGroup, InputGroupAddon, Col, FormText, Modal, ModalBody } from "reactstrap";
import { ERROR_REQUIRED } from "constants/index"
import { useHistory } from "react-router-dom";


const RegisterPage = (props) => {

    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('')
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('')
    const [contactNumber, setContactNumber] = useState('');
    const [contactNumberErr, setContactNumberErr] = useState('');

    const [modal, setModal] = React.useState(false);
    const [modalMsg, setModalMsg] = React.useState('');

    const history = useHistory();

    const toggleModal = (msg) => {
        setModal(!modal);
        setModalMsg(msg);
    };

    const handleEmailAddressChange = event => {
        setEmail(event.target.value);
        setEmailErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }

    const handlePasswordChange = event => {
        setPassword(event.target.value);
        setPasswordErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
    }

    const handleContactNumberChange = event => {
        setContactNumber(event.target.value);
        // setContactNumberErr(event.target.value.length > 0 ? '' : ERROR_REQUIRED)
        setContactNumberErr( event.target.value.length === 10 ? '' : 'Must be 10 digit number')
    }

    const validate = event => {
        let hasErrors = false;

        if (!email) {
            setEmailErr(ERROR_REQUIRED)
            hasErrors = true;
        }
        if (!password) {
            setPasswordErr(ERROR_REQUIRED);
            hasErrors = true;
        }
        if (password && password.length < 8) {
            setPasswordErr('Must be atleast 8 characters');
            hasErrors = true;
        }

        if (!contactNumber) {
            setContactNumberErr(ERROR_REQUIRED);
            hasErrors = true;
        }
        if(contactNumber.length !== 10){
            setContactNumberErr( 'Must be 10 digit number')
            hasErrors = true;

        }
        return hasErrors;
    }

    const handleFormSubmit = event => {
        event.preventDefault();

        if (validate()) {
            return;
        }
        const user_object = {
            email: email,
            password: password,
            contactNumber: contactNumber
        };

        signup(user_object)
            .then(response => {
                alert("You're successfully registered")
                // history.push("/update/address")
                 history.push("/login")
            }).catch(error => {

                if(error.message){
                    toggleModal(error.message);
                } else {
                    toggleModal('Unable to create account')
                }
            });
    }


    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("profile-page");
        return function cleanup() {
            document.body.classList.remove("profile-page");
        };
    });

    return (
        <>
            <div className="main ">
                <br />
                <div className="section">
                    <Modal className="modal-dialog-centered" isOpen={modal} toggle={() => toggleModal()}>
                        <ModalBody className="text-center">
                        <i className="nc-icon nc-alert-circle-i text-info align-middle" /> &nbsp;
                            {modalMsg}
                        </ModalBody>
                    </Modal>
                    <Col className="m-auto" sm="12" lg="8" md="8">
                        <div className="filter" />
                        <Col className="ml-auto mr-auto" lg="4">
                            <Card className="card card-body mt-3 ml-auto mr-auto bg-light text-dark">
                            <img alt="..." className="mx-auto" width="50%" src="assets/img/mycook-3.png" />

                                <h3 className="mx-auto">Create Account</h3>
                                {/* <small className="mx-auto">Or Log In via</small>
                                <div className="social-line text-center">
                                    <Button className="btn-neutral btn-just-icon mr-1" color="facebook" href={FACEBOOK_AUTH_URL} >
                                        <i className="fa fa-facebook-square" />
                                    </Button>
                                    <Button
                                        className="btn-neutral btn-just-icon mr-1"
                                        color="google"
                                        href={GOOGLE_AUTH_URL}
                                    >
                                        <i className="fa fa-google-plus" />
                                    </Button>
                                </div>

                                <br /> */}

                                <Form >
                                    <label>Email</label>
                                    <Input placeholder="Email" type="text" value={email} onChange={handleEmailAddressChange} />
                                    <FormText className="text-danger">  {emailErr ? emailErr : ''}</FormText>

                                    <label>Password</label>
                                    <Input placeholder="Password" type="password" value={password} onChange={handlePasswordChange} />
                                    <FormText className="text-danger">  {passwordErr ? passwordErr : ''}</FormText>

                                    <label>Contact Number</label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">+63</InputGroupAddon>
                                        <Input type="number" value={contactNumber} onChange={handleContactNumberChange} />

                                    </InputGroup>
                                    <FormText className="text-danger">  {contactNumberErr ? contactNumberErr : ''}</FormText>
                                    <br />
                                    <Button onClick={handleFormSubmit} block className="btn-round btn-timmy">  Sign Up  </Button>

                                </Form>
                                <Button
                                    className="btn-link text-muted"
                                    href="/login"
                                > Already have an account? </Button>
                            </Card>
                        </Col>
                    </Col>
                </div>

            </div>
        </>
    );
}

export default RegisterPage;
