import { isObjectEmpty } from "helpers/utils";
import { useHistory } from "react-router-dom";


export const isAuthUser = (user) => {
  let isAuthUser = true;
  if (!isObjectEmpty(user)) {
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    isAuthUser = false;
  }
  return { isAuthUser };
}

export const AuthReducer = ( state, action ) => {

  switch (action.type) {
    
    case "success":
      return {
        ...state,
        ...isAuthUser(action.payload),
        user: action.payload
      };
    case "API_ERROR":
      return {
        ...state,
        error: action.payload
      };
    case "SET_LOADER":
      return {
        ...state,
        isLoading: action.payload
      };
    case "LOGOUT":

      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      window.location.href = "https://timmyexpress.com/login";
      return {
        ...state,
        ...isAuthUser(),
      };
    default:
      return state;
  }
};