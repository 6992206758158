import React from "react";

import { askForPermissioToReceiveNotifications } from "pwa/push-notification";

const Notification = () => {

  const [time, setTime] = React.useState('');

  React.useEffect(() => {
    const token = askForPermissioToReceiveNotifications();
  }, [time]);

  return (
    <>
    </>
  );

}

export default Notification;
