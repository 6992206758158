import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Input from 'reactstrap/lib/Input';
import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import { updateAddress } from 'helpers/API';
import { useHistory } from "react-router-dom";
import Geocode from "react-geocode";
import { AuthContext } from 'contexts/AuthContext';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import Container from 'reactstrap/lib/Container';
import { updateCity } from 'helpers/API';
import { getCurrentUser } from 'helpers/API';

Geocode.setApiKey("AIzaSyByIbKHE6VecHzfPrDDKiBTit7FdIPmKYk");
Geocode.setLanguage("en");

export class MapContainer extends Component {
  static contextType = AuthContext;
  static history = useHistory;

  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: '',
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      savedCity: 'Bayugan',

      mapCenter: {
        lat: 8.711552874268438,
        lng: 125.74483363383791
      },
      containerStyle: {
        position: 'fixed',
        width: '100%',
        height: '100%'
      },
      style: {
        position: 'relative',

        width: '100%',
        height: '100%'
      },
      markerCenter: {},
      modal: false,
      loading: false,
      marker: {
        name: "Current position",
        position: {
          lat: 8.7169235,
          lng: 125.75159619999998
        }
      },
      landmark: '',
      city: ''
    };
  }

  handleAddressChange = event => {
    this.setState({ address: event.target.value });
  };

  handleLandmarkChange = event => {
    this.setState({ landmark: event.target.value });
  };

  searchAddress = () => {
    if (!this.state.address) {
      alert('Please enter your address')
      return;
    }
    // geocodeByAddress(this.state.address)
    //   .then(results => 

    //     getLatLng(results[0]))

    //   .then(latLng => {
    //     console.log('Success', latLng);
    //     this.setState({ mapCenter: latLng });
    //   })
    //   .catch(error =>
    //     alert('Error fetching your address'));

    let city = '';
    if (this.state.savedCity === 'San Fransisco') {
      city = 'San Fransisco, Agusan del Sur'
    }

    geocodeByAddress(this.state.address + this.state.savedCity)
      .then(results => {
        console.log(results)
        const cityFound = results[0].address_components[1].long_name;
        this.setState({
          city: cityFound
        })

        return getLatLng(results[0])
      }).then(latLng => {
        console.log('Success', latLng);
        this.setState({ mapCenter: latLng });
      })
      .catch(error =>
        alert('Error fetching your address'));
  };

  onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();


    this.setState({
      mapCenter: {
        lat: lat,
        lng: lng
      }
    });

  };

  validateAddress = () => {
    let isValid = true;
    if (!this.state.address) {
      isValid = false;
      alert('Please input your street')
    }
    if (!this.state.mapCenter.lat && !this.state.mapCenter.lng) {
      isValid = false;
      alert('Please pin exact location')
    }

    return isValid;
  }

  onSubmit = () => {
    // this.toggleModal();

    const auth = this.context;

    if (this.validateAddress()) {

      const updatedAddress = {
        city: this.state.savedCity,
        landmark: this.state.landmark,
        locationAddress: this.state.address,
        latitude: this.state.mapCenter.lat,
        longitude: this.state.mapCenter.lng
      };
      this.setState({ loading: true })

      updateAddress(updatedAddress)
        .then(response => {
          auth.success(response);
          this.toggleModal();

          // alert("Successfully updated address");
          window.location.href = "https://timmyexpress.com";
        }).catch(error => {
          console.log(error)
        });
    }
    // this.toggleModal();

  }

  componentDidMount() {
    const auth = this.context;

    if (auth.user.address && auth.user.address.locationAddress && auth.user.address.latitude && auth.user.address.longitude) {
      this.setState({
        // address: auth.user.address.locationAddress,
        mapCenter: {
          lat: auth.user.address.latitude,
          lng: auth.user.address.longitude
        },
        savedCity: auth.user.address.city ? auth.user.address.city : 'Bayugan',
        address: auth.user.address.locationAddress,
        landmark: auth.user.address.landmark
      });
    } else {
      this.getCurrentPos();
    }
  }

  getCurrentPos = () => {
    let self = this;

    navigator.geolocation.getCurrentPosition(function (position) {

      const lat = position.coords.latitude;
      const long = position.coords.longitude;

      Geocode.fromLatLng(lat, long).then(
        (response) => {
          const foundAddress = response.results[0].formatted_address;
          const cityFound = response.results[0].address_components[1].long_name;

          console.log(foundAddress);

          self.setState({
            address: foundAddress,
            city: cityFound
          });
        },
        (error) => {
          console.error(error);
          alert('Error fetching your address');
        }
      );

      self.setState({
        mapCenter: {
          lat: lat,
          lng: long
        }
      });
    });
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    })

  };

  handleCityChange = event => {
    this.setState({
      savedCity: event.target.value
    })

    let lat = null;
    let lng = null;
    if (event.target.value === "Bayugan") {
      lat = 8.7143526;
      lng = 125.7480952;
    } else if (event.target.value === "San Fransisco") {
      lat = 8.50948581520426;
      lng = 125.97266977409667;
    } else if (event.target.value === "Siargao") {
      lat = 9.784629287234301;
      lng = 126.15651450583499;
    }


    this.setState({
      mapCenter: {
        lat: lat,
        lng: lng
      }
    })

  }

  submitCity = event => {
    const auth = this.context;

    console.log('city: ' + this.state.savedCity)
    updateCity(this.state.savedCity).then(response => {
      getCurrentUser().then(response => {
        auth.success(response);
      })
      this.searchAddress();
    }).catch(error => {
      console.log('city: ' + this.state.savedCity)

    })
  }
  render() {
    return (
      <>
        <Modal className="modal-dialog-centered" isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalBody>
            Please wait
            <img width="10%" alt="Loading" className="img-responsive mr-auto ml-auto mb-0" src="assets\img\loading2.gif" />
          </ModalBody>
        </Modal>

        <Map
          containerStyle={this.state.containerStyle}
          style={this.state.style}
          google={this.props.google}
          initialCenter={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          center={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          onClick={this.onMapClicked}
          zoom={15}
        >
          <Marker
            position={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }}
            draggable={true}
            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
            name={this.state.marker.name}
          />
        </Map>
        <footer className="footer-next"><br />
          <Container>
            <Row>
              <Col xs="12" >
                <Label className="label text-danger text-left">Note: Allow GPS or drag the red marker to point to your delivery address. Delivery rate varies depending on your location</Label>
                <FormGroup>
                  <Input type="select" name="select" value={this.state.savedCity} id="exampleSelect" onChange={this.handleCityChange}>
                    <option>Bayugan</option>
                    <option>San Fransisco</option>
                    <option>Siargao</option>
                  </Input>
                </FormGroup>
                <InputGroup>
                  <Input type="text" value={this.state.address} onChange={this.handleAddressChange} placeholder="Enter Street / Subd " />
                  <InputGroupAddon addonType="append"><Button className="btn-timmy" onClick={this.searchAddress}><i className="nc-icon nc-compass-05"></i></Button></InputGroupAddon>
                </InputGroup>
                <Input type="text" value={this.state.landmark} onChange={this.handleLandmarkChange} placeholder="Enter Rider Notes / Blk, House # / Landmark" />
                {/* <Input type="text" value={this.state.savedCity} disabled /> */}


              </Col>
            </Row>
            <Row>
              <Col>
                <Button size="sm" onClick={this.onSubmit} className="btn-timmy float-right">Save<i className="nc-icon nc-minimal-right" /></Button>
                <Button size="sm" href="/restaurants" className="btn-timmy float-left"><i className="nc-icon nc-minimal-left" /></Button>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyByIbKHE6VecHzfPrDDKiBTit7FdIPmKYk')
})(MapContainer)