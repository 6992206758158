import React from "react";
import ProfilePageHeader from "components/common/ProfilePageHeader.js";
import ProfilePageSection from "components/restaurant/ProfilePageSection.js";
import Footer from "components/common/Footer.js";
import ProductsContext from "contexts/ProductsContext";
import { useHistory, useParams } from "react-router-dom";
import { getShopById } from 'helpers/API';
import RestaurantNavBar from "./RestaurantNavBar";

class RestaurantPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cart: {
        numberOfItems: 0,
        totalPrice: 0
      },
      slots: {},
      reviews: {},
      slotAvailable: 0
    }
  }

  async getShops(){
    let params = useHistory;
    console.log('params: ' + params)

   getShopById(params).then((data) => {
    return data;
    })
  }

  render() {
    
    const shop = this.props.location.state.shop;
    const slots = this.state.slots;
    const reviews = this.state.reviews;
    const slotAvailable = this.state.slotAvailable;
    const contextValues = { shop, slots, reviews, slotAvailable, ...this.state }

    return (
      <>
        <RestaurantNavBar />
        <ProductsContext.Provider value={contextValues}>
          <ProfilePageHeader />
          <ProfilePageSection />
        </ProductsContext.Provider>
        <Footer />
      </>
    );
  }
}
export default RestaurantPage;