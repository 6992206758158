import { AuthContext } from "contexts/AuthContext";
import React from "react";
import { Redirect, Route } from "react-router";

const AuthRoute = props => {
  const { type } = props;
  const { isAuthUser, user } = React.useContext(AuthContext);

  if (type == "guest" && isAuthUser) {
    return props.page;
  } else if (type == "private" && !isAuthUser) {
    
    return <Redirect to={{
      pathname: "/login",
      state: {
        from: props.path
      }
    }} />;
  }

  return <Route {...props} />;
};

export default AuthRoute;